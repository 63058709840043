import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  EcaseHttpService, ECaseNumberFormatterPipe,
  ECaseSnackBarService, ECaseSplashScreenService,
  ECaseUtils,
  LoginAuthenticationService,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {Router} from '@angular/router';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import cloneDeep from 'lodash/cloneDeep';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {FbButtonComponentDialogComponent} from "./fb-button-component-dialog/fb-button-component-dialog.component";
import {map} from "rxjs/operators";

@Component({
  selector: 'fb-button',
  templateUrl: './fb-button.component.html',
  styleUrls: ['./fb-button.component.scss']
})
export class FbButtonComponent implements OnInit {
  @Input() selectedLanguage: any;
  @Input() selectedSectionId: string;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() rowNumber;
  @Input() offlineModeEnabled;
  @Input() isFormbuilderModeEnabled: boolean;
  @Input() isLastPosition;
  @Input() sections;
  showAdditionalConfirmation = false;

  constructor(private translate: TranslateService,
              private eCaseHttpService: EcaseHttpService,
              private refreshDataService: RefreshDataService,
              private router: Router,
              private pendingChangesGuard: PendingChangesGuard,
              private loginAuthenticationService: LoginAuthenticationService,
              private eCaseSnackBarService: ECaseSnackBarService,
              private eCaseSplashScreenService: ECaseSplashScreenService,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              public dialog: MatDialog) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
  }

  ngOnInit() {
    if (!(this.bphChange)) {
      this.bphChange = {};
      this.bphChange.value = '';
      this.bphChange.error = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = '';
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (!this.bphChange.infoText) {
      this.bphChange.infoText = {};
    }
    if (this.confChange) {
      if (!this.confChange.buttonColor) {
        this.confChange.buttonColor = '#0A9AE4';
      }
      if (this.confChange.showAdditionalConfirmation || this.confChange.isShowComponentOnClick) {
        this.showAdditionalConfirmation = (this.confChange.showAdditionalConfirmation || this.confChange.isShowComponentOnClick);
      }
    }
    /*if(!this.bphChange.rowNumber && this.rowNumber){
      this.bphChange.rowNumber = this.rowNumber;
    }*/

    /* this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
       this.selectedLanguage = params.lang;
     });*/
    if (this.confChange.executeOnInit) {
      console.log('exec INIT');
      this.confChange.executeOnInit(this.globalConf, this.globalPrj, this.selectedLanguage, this.rowNumber, this.eCaseHttpService, this.eCaseSnackBarService);
    }
  }

  async executeOnClickJs() {
    try {
      console.log('executeOnClickJs');
      this.globalConf.baseUrl = this.loginAuthenticationService.baseUrl;
      this.globalConf.refreshDataService = this.refreshDataService;
      this.globalConf.router = this.router;
      this.globalConf.pendingChangesGuard = this.pendingChangesGuard;
      this.globalConf.eCaseUtils = ECaseUtils;
      this.globalConf.eCaseSplashScreenService = this.eCaseSplashScreenService
      this.globalConf.sections = this.sections;
      this.globalConf.rxjsMap = map;
      let modifiedHttpService = this.eCaseHttpService;
      if (this.isFormbuilderModeEnabled) {
        modifiedHttpService = cloneDeep(this.eCaseHttpService);
        modifiedHttpService.get = (url: string, options?, isIgnoreError?): Observable<any> => {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode', this.selectedLanguage));
          return of({
            'status': false,
            'errorMessage': ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode')
          });
        };
        modifiedHttpService.post = (url: string, body: any | null, options?, isIgnoreError?): Observable<any> => {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode', this.selectedLanguage));
          return of({
            'status': false,
            'errorMessage': ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode')
          });
        };
      }
      if (this.confChange.executeOnClickJs) {
        this.confChange.executeOnClickJs(this.globalConf, this.globalPrj, this.selectedLanguage, this.rowNumber, modifiedHttpService, this.eCaseSnackBarService, this);
        await new Promise(resolve => resolve(''));
      } else if (this.confChange.executeAsyncOnClickJs) {
        const prj = await this.confChange.executeAsyncOnClickJs(this.globalConf, this.globalPrj, this.selectedLanguage, this.rowNumber, modifiedHttpService, this.eCaseSnackBarService, this);
        if (prj && prj[this.selectedSectionId]) {
          this.globalPrj = prj;
        }
      }
      if(this.confChange.isOnSuccessDialogEnabled) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '600px',
          data:
            {
              dialog: this.confChange.successDialogText,
              DialogTextTwo: this.confChange.successDialogTextTwo,
              showConfirmOption: false,
              showActionButtons: false,
              selectedLanguage: this.selectedLanguage
            }
        });
      }
    } catch (e) {
      console.error(e);
    }
    if (this.confChange.isSaveOnClickEnabled) {
      const data: any = {};
      data.operation = 'Save Without Validation';
      data.prj = this.globalPrj;
      this.refreshDataService.saveForm(data);
    }
  }


  openRenderedComponent(dataForComponent: any): void {
    console.log(this.confChange);
    if (this.confChange.beforeRenderDataMapJS) {
      eval(this.confChange.beforeRenderDataMapJS);
    }
    const dialogRef = this.dialog.open(FbButtonComponentDialogComponent, {
      width: '800px'
    })
    const instance = dialogRef.componentInstance;
    instance.dataForComponent = dataForComponent;
    instance.confChange = this.confChange;
    instance.selectedLanguage = this.selectedLanguage;
    dialogRef.afterClosed().subscribe((decision) => {
      if (decision) {
        this.executeOnClickJs();
      }
    });
  }

  openDialog() {
    if (this.confChange.isShowComponentOnClick) {
      const globalPrj = this.globalPrj;
      const confChange = this.confChange;
      const globalConf = this.globalConf;
      const globalComponent = this;
      if (this.confChange.componentMetadataJS) {
        eval(this.confChange.componentMetadataJS);
      }
      this.globalPrj = globalPrj;
      this.confChange = confChange;
      const updatedObjectToPostJS = cloneDeep(this.confChange.renderedComponent.objectToPostJS);
      Object.keys(this.confChange.renderedComponent.objectToPostJS).map(key => {
        const evalString = (updatedObjectToPostJS[key] + "").includes('rowIndex') ? updatedObjectToPostJS[key].replace('rowIndex', this.rowNumber) : updatedObjectToPostJS[key] ;
        updatedObjectToPostJS[key] = eval('const calculateValue = (globalPrj) => { return ' + evalString + '}; calculateValue(globalPrj)');
      });
      this.confChange.renderedComponent.context = this.confChange.renderedComponent?.properties?.context || {};
      const updatedContext = cloneDeep(this.confChange.renderedComponent.context);
      Object.keys(this.confChange.renderedComponent.context).map(key => {
        const evalString = (updatedContext[key] + "").includes('rowIndex') ? updatedContext[key].replace('rowIndex', this.rowNumber) : updatedContext[key] ;
        updatedContext[key] = eval('const calculateValue = (globalPrj) => { return ' + evalString + '}; calculateValue(globalPrj)');
      });
      this.confChange.renderedComponent.objectToPostJS = cloneDeep(updatedObjectToPostJS);
      this.confChange.renderedComponent.properties.context = cloneDeep(updatedContext);
      this.confChange.renderedComponent.context = cloneDeep(updatedContext);
      if (this.confChange.componentDataLoadUrl && this.confChange.renderedComponent.objectToPostJS) {
        this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + this.confChange.componentDataLoadUrl, this.confChange.renderedComponent.objectToPostJS)
          .subscribe((response: any) => {
            if (response.status) {
              this.openRenderedComponent(response.data);
            } else {
              this.eCaseSnackBarService.show('failure', response.errorMessage);
            }
          });
      } else {
        this.openRenderedComponent([]);
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '600px',
        data:
          {
            dialog: this.confChange.dialogText,
            showConfirmOption: true,
            selectedLanguage: this.selectedLanguage
          }
      });
      dialogRef.afterClosed().subscribe((decision) => {
        if (decision) {
          this.executeOnClickJs();
        }
      });
    }
  }


}
