import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoginAuthenticationService} from './login-authentication.service';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReviewService implements CanActivate {

  committeeId: number;
  applicationId: number;
  objectType: string;
  objectId: number;
  committeeStageId: number;
  rcgId: number;
  disabled: boolean;
  asHistory: boolean;
  lastTaskListFilters: any;
  lastTaskListGroupings: Set<any>;
  lastTaskListSort: any;
  lastTaskListPaginator: any;
  lastTaskHistoryFilters: any;
  lastTaskHistoryListSort: any;
  lastTaskHistoryPaginator: any;
  taskTabCode: string;
  isAnonymized: boolean;

  private _tasktabSelectedSubject = new Subject<any>();
  private _taskSelected = new Subject<any>();
  private _taskConflictChange = new Subject<any>();


  constructor(public auth: LoginAuthenticationService, public router: Router, private eCaseHttpService: EcaseHttpService, private location: Location) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /*return this.eCaseHttpService.get(`api/grants/reviewer/canAccessTask/${route.params['objectType']}/${route.params['objectId']}`).pipe(map((value) => {
      if (value) {*/
        this.objectType = route.params['objectType'];
        this.objectId = parseInt(route.params['objectId']);
        return true;
      /*}
      return value;
    }));*/
  }

  isDisabled(){
    return this.disabled;
  }

  get onTaskTabSelected() {
    return this._tasktabSelectedSubject.asObservable();
  }
  selectTaskTab(event) {
    this.taskTabCode = event;
    this._tasktabSelectedSubject.next(event);
  }

  get onTaskConflictChange() {
    return this._taskConflictChange.asObservable();
  }
  changeTaskConflict(event) {
    this._taskConflictChange.next(event);
  }

  get onTaskSelected() {
    return this._taskSelected.asObservable();
  }
  selectTask(event) {
    this._taskSelected.next(event);
  }

  getReviewerDashboard() {
    return this.eCaseHttpService.get('api/grants/reviewer/getDashboard');
  }

  getReviewerTasksInProgress() {
    return this.eCaseHttpService.get('api/grants/reviewer/getTasksInProgress');
  }

  getReviewerTasks() {
    return this.eCaseHttpService.get('api/grants/reviewer/getTasks');
  }

  getTasksPa() {
    return this.eCaseHttpService.get('api/grants/reviewer/getTasksPa');
  }

  getTasksHistory() {
    return this.eCaseHttpService.get('api/grants/reviewer/getTasksHistory');
  }

  getReviewerTask(objectType: string, objectId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getTask/${objectType}/${objectId}`);
  }

  getApplicationDetails(panelId: number, applicationId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getApplicationDetails/${panelId}/${applicationId}`);
  }

  getPlenaryStatus(objectType: string, objectId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getMeetingStatus/${objectType}/${objectId}`);
  }

  getReviewerTaskForm(objectType: string, objectId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getTaskForm/${objectType}/${objectId}`);
  }

  completeTaskAdmin(objectType:String, objectId:number) {
    return this.eCaseHttpService.post(`api/grants/reviewer/completeTaskAdmin/${objectType}/${objectId}`, {});
  }

  saveReviewerTaskForm(objectType: string, objectId: number, taskForm){
    return this.eCaseHttpService.post(`api/grants/reviewer/saveTask/${objectType}/${objectId}`, taskForm);
  }

  completeReviewerTaskForm(objectType: string, objectId: number, taskForm) {
    return this.eCaseHttpService.post(`api/grants/reviewer/completeTask/${objectType}/${objectId}`, taskForm);
  }

  getReviewerTaskHistory(objectType: string, objectId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getTaskHistory/${objectType}/${objectId}`);
  }

  getCommitteeApplicationReviews(committeeStageId: number, applicationId: number, objectType: String = null) {
    const url = objectType === null ?
      `api/grants/reviewer/getCommitteeStageApplicationReviews/${committeeStageId}/${applicationId}` :
      `api/grants/reviewer/getCommitteeStageApplicationReviews/${committeeStageId}/${applicationId}?objectType=${objectType}`;
    return this.eCaseHttpService.get(url);
  }

  getCommitteeApplicationNominations(committeeStageId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getCommitteeStageApplicationNominations/${committeeStageId}`);
  }

  getMemberApplicationTasksHistory(panelId: number, applicationId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getMemberApplicationTasksHistory/${panelId}/${applicationId}`);
  }

  getMemberPanelTasksHistory(panelId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getMemberPanelTasksHistory/${panelId}`);
  }

  getCommitteeApplicationReview(reviewId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getCommitteeStageApplicationReview/${reviewId}`);
  }

  getReviewSelfAssessmentTypes() {
    return this.eCaseHttpService.get('/api/grants/reviewer/getReviewSelfAssessmentTypes');
  }

  getReviewPlenarySummary() {
    return this.eCaseHttpService.get('/api/grants/reviewer/getReviewPlenarySummary');
  }

  getDirectorReviewDecisions() {
    return this.eCaseHttpService.get('/api/grants/reviewer/getDirectorReviewDecisions');
  }

  getDirectorReviewCommentTypes(rdrId: number) {
    return this.eCaseHttpService.get(`/api/grants/reviewer/getDirectorReviewCommentTypes/${rdrId}`);
  }

  getPlenaryDecisions() {
    return this.eCaseHttpService.get('/api/grants/reviewer/getPlenaryDecisions');
  }

  getPlenaryCommentTypes(rmpId: number) {
    return this.eCaseHttpService.get(`/api/grants/reviewer/getPlenaryCommentTypes/${rmpId}`);
  }

  getApplicationDocuments(applicationId: number, panelRoundId: number) {
    return this.eCaseHttpService.get(`/api/grants/reviewer/getApplicationDocuments/${applicationId}/${panelRoundId}`);
  }

  getApplicationDocumentsPaReview(applicationId: number) {
    return this.eCaseHttpService.get(`/api/grants/reviewer/getApplicationDocumentsPaReview/${applicationId}`);
  }

  getReviewFormJsonData(reviewReviewId: number) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getReviewFormJsonData', {
      reviewReviewId: reviewReviewId
    });
  }

  getPaReviewFormJSONAndActions(formId) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getPaReviewFormJSONAndActions', {formId: formId});
  }

  getPaReviewFormJsonData(paReportReviewId: number) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getPaReviewFormJsonData', {
      paReportReviewId: paReportReviewId
    });
  }


  getReviewNominationFormJsonData(body: any = {}) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getReviewNominationFormJsonData', body);
  }

  getPlenaryFormJsonData(rmsId) {
    return this.eCaseHttpService.post(`/api/grants/reviewer/getPlenaryFormJsonData/${rmsId}`, {});
  }

  getReviewFormJSONAndActions(reviewReviewId) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getReviewFormJSONAndActions', {reviewReviewId: reviewReviewId});
  }

  getReviewNominationFormJSONAndActions(reviewNominationCsuId) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getReviewNominationFormJSONAndActions', {reviewNominationCsuId: reviewNominationCsuId});
  }

  getPlenaryFormJSONAndActions(rmsId) {
    return this.eCaseHttpService.post('/api/grants/reviewer/getPlenaryFormJSONAndActions', {reviewMeetingScoringId: rmsId});
  }

  getReviewerTaskRevisionForm(objectType: string, objectId: number) {
    return this.eCaseHttpService.get(`api/grants/reviewer/getReviewerTaskRevisionForm/${objectType}/${objectId}`);
  }

  getAllReviewForms() {
    return this.eCaseHttpService.get('api/grants/reviewer/getAllReviewForms');
  }

  reopenTask(objectType, objectId) {
    return this.eCaseHttpService.post('/api/grants/reviewer/reopenTask', {objectType: objectType, objectId: objectId});
  }
}
