<div *ngIf="isValidHtml && !confChange.hide && !_bphChange.hide"
     [ngClass]="_bphChange.error && _bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">

  <ng-container
    *ngTemplateOutlet="RecursiveReviewCriteriaGrid; context:{ 'dataArray': _bphChange.rows}"></ng-container>

  <ng-container *ngIf="_bphChange?.error[selectedLanguage]">
    <br>
    <span class="tableFormFieldError">{{_bphChange.error[selectedLanguage]}}</span>
  </ng-container>
</div>


<td *ngIf="isValidTable" [id]="'td_' + confChange.name + '_' + rowNumber"
    [ngClass]="_bphChange.error && _bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 10%;padding-right:10px;padding-left:10px">
  <div class="field {{_bphChange.error_class}} hide_{{confChange.show}}">
    <!--No implementation required for inside table -->
  </div>
</td>


<td *ngIf="isValidTableReadonly  && !confChange.hideCell && !confChange.hideCell"
    [attr.rowspan]="confChange.rowspan"
    [ngClass]="_bphChange.error && _bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 1%;padding-left:10px;padding-right:10px">
  <div class="field hide_{{confChange.show}} {{_bphChange.error_class}}">
    <!--No implementation required for inside a table in readonly mode -->
  </div>
</td>


<ng-template #RecursiveReviewCriteriaGrid let-dataArray="dataArray">
  <ng-container *ngFor="let child of dataArray;first as isFirst; last as isLast; let idx = index;">

    <ng-container *ngIf="!child.child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] && !isFirst && !(!dataArray[idx - 1] || !dataArray[idx - 1].child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'])">
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngIf="!child.child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] && !isFirst">
      <br>
    </ng-container>

    <ng-container [ngSwitch]="child['REVIEW_CRITERIA_GRID_ELEMENT_TYPE']">
      <ng-container *ngSwitchCase="'Label'">
        <ng-container *ngTemplateOutlet="label; context: child"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Description'">
        <ng-container *ngTemplateOutlet="description; context: child"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Comment Field'">
        <ng-container *ngTemplateOutlet="commentField; context: child"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Quotation Field'">
        <ng-container *ngTemplateOutlet="quotationField; context: child"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Dropdown List Field'">
        <ng-container *ngTemplateOutlet="dropdownListField; context: child"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #label let-child="child">
  <br *ngIf="child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID']">
  <div *ngIf="!child.hide" [style.padding-left.px]="child.paddingLeft ? child.paddingLeft : '0'">
    <span *ngIf="child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_IS_MANDATORY" style="color: red">* </span>
    <span *ngIf="child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION && child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION[selectedLanguage]"
          [innerHTML]="child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION[selectedLanguage]"
          [style]="child.style"></span>
    <br>
  </div>
</ng-template>

<ng-template #description let-child="child">
  <br *ngIf="child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID']">
  <div *ngIf="!child.hide" [style.padding-left.px]="child.paddingLeft ? child.paddingLeft : '0'">
    <span *ngIf="child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_IS_MANDATORY" style="color: red">* </span>
    <span *ngIf="child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION && child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION[selectedLanguage]" [innerHTML]="child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION[selectedLanguage]"
          [style]="child.style"></span>
    <br>
  </div>
</ng-template>

<ng-template #commentField let-child="child">
  <div *ngIf="!child.hide" [ngClass]="child?.error && child?.error[selectedLanguage] ? 'hasError' : 'noError'"
       [style.padding-left.px]="child.paddingLeft ? child.paddingLeft : '0'">
    <mat-form-field [ngClass]="child?.error && child.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"
                    appearance="outline"
                    class="mat-form-field">
        <textarea (input)="checkMaxComment($event, child);" [(ngModel)]="child.value"
                  attr.aria-label="{{'ecase.formbuilder.review.criteria.grid.commentfield' | translate}}"
                  eCaseDragAndDropTextarea
                  id="ecase_formbuilder_review_criteria_grid_commentfield_{{child.REVIEW_CRITERIA_GRID_ELEMENT_ID}}"
                  matInput
                  [disabled]="isDisabledForm === true && isReviewCommentDeadlinePassed === true"
                  (blur)="checkRequired(child, child['REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_IS_MANDATORY'] === 'true')"
                  [required]="child['REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_IS_MANDATORY'] === 'true'"
                  rows="5"></textarea>
    </mat-form-field>
    <span *ngIf="child?.error && child?.error[selectedLanguage]" class="formFieldError"
          style="margin-top: -20px">{{child.error[selectedLanguage] | eCaseTranslate}}</span>
    <div class="field" style="text-align: right;">
      <p
        *ngIf="child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH && child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH_UNIT !=='C'"
        class="counter-style"
        style="margin-top: -20px">{{getWordCount(child.value)}} /
        {{child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH}} {{'ecase.common.words' | translate}}</p>
      <p
        *ngIf="child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH && child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH_UNIT ==='C'"
        class="counter-style"
        style="margin-top: -20px">{{getTextLength(child.value)}} /
        {{child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH}} {{'ecase.common.characters' | translate}}</p>
    </div>
  </div>
</ng-template>

<ng-template #quotationField let-child="child">
  <div *ngIf="!child.hide" [ngClass]="child?.error && child?.error[selectedLanguage] ? 'hasError' : 'noError'"
       [style.padding-left.px]="child.paddingLeft ? child.paddingLeft : '0'">
    <div fxLayout="row"
         [style.width]="getWidthFromMaxlength(child, child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE.length) ">
      <mat-form-field [ngClass]="child?.error && child.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"
                      appearance="outline">
        <ng-container *ngIf="!confChange.decimalSeparator || confChange.decimalSeparator ===''">
          <input (keyup)="checkMaxQuotation($event, child);"
                 [disabled]="child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_TOTAL'] === '1' || globalReadOnlyMode"
                 [(ngModel)]="child.value"
                 [attr.aria-label]="'ecase.formbuilder.review.criteria.grid.quotationField' | translate"
                 min="0"
                 id="ecase_formbuilder_review_criteria_grid_quotationField_{{child.REVIEW_CRITERIA_GRID_ELEMENT_ID}}"
                 matInput
                 eCaseNumberField [lang]="selectedLanguage"
                 [isNegativeAllowed]="false"
                 [isCurrency]="false"
                 [decimalPlacesAllowed]="true"
                 (blur)="checkRequired(child, child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_MANDATORY'] === 'true')"
                 [required]="child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_MANDATORY'] === 'true'"
                 type="text">
          <span matSuffix style="position: absolute; right: 10px;">/ {{child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE}}</span>
        </ng-container>
        <ng-container *ngIf="confChange.decimalSeparator && confChange.decimalSeparator !==''">
          <input (keyup)="checkMaxQuotation($event, child);"
                 [disabled]="child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_TOTAL'] === '1' || globalReadOnlyMode"
                 [(ngModel)]="child.value"
                 [attr.aria-label]="'ecase.formbuilder.review.criteria.grid.quotationField' | translate"
                 min="0"
                 id="eecase_formbuilder_review_criteria_grid_quotationField_{{child.REVIEW_CRITERIA_GRID_ELEMENT_ID}}"
                 matInput
                 (blur)="checkRequired(child, child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_MANDATORY'] === 'true')"
                 [required]="child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_MANDATORY'] === 'true'"
                 type="text">
          <span matSuffix style="position: absolute; right: 10px;">/ {{child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE}}</span>
        </ng-container>
      </mat-form-field>
      <div
        *ngIf="child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_TOTAL'] === '1' && othersReviewersScores && othersReviewersScores.length > 0"
        style="margin-top: -19px;margin-left: 100%;">
        <label>{{'ecase.formbuilder.review.criteria.grid.otherReviewersAvg' | eCaseTranslate}}</label>
        <mat-form-field appearance="outline">
          <input [disabled]="true"
                 [(ngModel)]="othersReviewersAvgScore"
                 [attr.aria-label]="'ecase.formbuilder.review.criteria.grid.quotationField' | translate"
                 id="ecase_formbuilder_review_criteria_grid_quotationField_other_reviewers_scores_avg"
                 matInput
                 type="number">
          <span matSuffix>/ {{othersReviewersMaxScore}}</span>
        </mat-form-field>
      </div>
      <div
        *ngIf="child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_TOTAL'] === '1' && globalPrj.formData.REVIEW_CRITERIA_GRID_RATING_SCALE.data.length > 0 && _bphChange.ratingScaleDetails && _bphChange.ratingScaleDetails.length > 0"
        style="margin-top: -19px;margin-left: 100%;">
        <ng-container>
          <table style="font-size: 13px;width: 200%;margin-bottom: 10px;margin-top: 20px;"
                 class="angular-table">
            <thead>
            <tr style="background-color: #dddddd;">
              <th style="color: black !important;padding: 2px 5px 2px 5px !important;">
                <div *ngIf="confChange.reviewCriteriaGridRatingScaleDetail;then ratingScaleDetailConf else ratingScaleDetail"></div>
                <ng-template #ratingScaleDetailConf>{{ confChange.reviewCriteriaGridRatingScaleDetail[selectedLanguage] }}</ng-template>
                <ng-template #ratingScaleDetail>{{'ecase.formbuilder.review.criteria.grid.ratingScaleDetail' | translate}}</ng-template>
              </th>
              <th style="color: black !important;padding: 2px 5px 2px 5px !important;">
                <div *ngIf="confChange.reviewCriteriaGridRatingScaleRange;then ratingScaleRangeConf else ratingScaleRange"></div>
                <ng-template #ratingScaleRangeConf>{{ confChange.reviewCriteriaGridRatingScaleRange[selectedLanguage] }}</ng-template>
                <ng-template #ratingScaleRange>{{'ecase.formbuilder.review.criteria.grid.ratingScaleRange' | translate}}</ng-template>
              </th>
              <th style="color: black !important;padding: 2px 5px 2px 5px !important;">
                {{'ecase.formbuilder.review.criteria.grid.selectedRatingScale' | translate}}
              </th>
            </tr>
            </thead>
            <tbody class="angular-table-tbody">
            <tr style=""
                *ngFor="let detail of _bphChange.ratingScaleDetails; let i = index;"
                [style]="i % 2 === 0 ? '' : 'background-color: #EAEAEA'">
              <td style=" text-align: left !important;padding: 2px 5px 2px 5px">
                <span style="">{{detail["NAME_VAL"] ? detail["NAME_VAL"] : ( detail["NAME"] | translate) }}</span>
                <br/>
                <span style="font-size: 13px; font-style: italic;">{{detail["DESCRIPTION_VAL"] ? detail["DESCRIPTION_VAL"] : ( detail["DESCRIPTION"] | translate) }}</span>
              </td>
              <td *ngIf="detail['MIN_SCORE'] !== detail['MAX_SCORE']" style="text-align: center !important;padding: 2px 10px 2px 10px !important;">
                {{detail["MIN_SCORE"]}} - {{detail["MAX_SCORE"]}}
              </td>
              <td *ngIf="detail['MIN_SCORE'] === detail['MAX_SCORE']" style="text-align: center !important;padding: 2px 10px 2px 10px !important;">
                {{detail["MAX_SCORE"]}}
              </td>
              <td style=" text-align: center !important;padding: 2px 5px 2px 0 !important;">
                <input type="checkbox"
                       onclick="return false;"
                       [checked]="computeRating(detail['MIN_SCORE'], detail['MAX_SCORE'], i)"
                       [disabled]="isDisabledForm"/>
              </td>
            </tr>
            </tbody>
            <tfoot class="angular-table-tfoot" *ngIf="_bphChange.ratingScaleMaxScore && _bphChange.computedScore">
            <tr style="background-color: #dddddd;">
              <th style="color: black !important;padding: 2px 10px 2px 10px !important;">
                {{'ecase.formbuilder.review.criteria.grid.overallScore' | translate}}
              </th>
              <th colspan="2" style="color: black !important;padding: 2px 10px 2px 10px !important;">
                {{_bphChange.computedScore}}/{{_bphChange.ratingScaleMaxScore}}
              </th>
            </tr>
            </tfoot>
          </table>
        </ng-container>

      </div>
      <!--<div class="field" style="text-align: right;">
        <p class="counter-style" style="margin-top: -20px">{{getNumericTextLength(child.value)}}
          / {{child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE.length}} {{'ecase.common.characters' | translate}}</p>
      </div>-->
    </div>
    <span *ngIf="child?.error && child?.error[selectedLanguage]" class="tableFormFieldError">{{child.error[selectedLanguage] | eCaseTranslate}}</span>
  </div>
</ng-template>

<ng-template #dropdownListField let-child="child">
  <div *ngIf="!child.hide" [ngClass]="child?.error && child?.error[selectedLanguage] ? 'hasError' : 'noError'"
       [style.width]="getWidthFromMaxlength(child, 15) "
       [style.padding-left.px]="child.paddingLeft ? child.paddingLeft : '0'" class="nine wide column field">
    <mat-form-field [ngClass]="child.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                    appearance="outline"
                    style="width: 100%">
      <mat-select (ngModelChange)="change($event,confChange,child)" [(ngModel)]="child.value.value"
                  [disabled]="child.isReadOnlyField || globalReadOnlyMode"
                  id="ecase_formbuilder_review_criteria_grid_dropdownListField_{{child.REVIEW_CRITERIA_GRID_ELEMENT_ID}}">
        <mat-option>{{'ecase.common.pleaseSelect' | translate }}</mat-option>
        <ng-container *ngIf="globalConf.lovs[toNumber(child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_LOV_ID'])]">
          <mat-option
            *ngFor="let option of globalConf.lovs[toNumber(child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_LOV_ID'])].list"
            [value]="option.value">
            {{option.label[selectedLanguage]}}
          </mat-option>
        </ng-container>
        <mat-option *ngIf="child.hasOther" [value]="otherValue.value">{{'ecase.common.other' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="child.hasOther && (child.value.value === otherValue.value)">
      <br>
      <label [for]="child.blockName + 'other value'"><span *ngIf="child.isOtherFieldRequired"
                                                           style="color: red">* </span>{{child.otherPlaceholder[selectedLanguage]}}
      </label>
      <mat-form-field appearance="outline">
        <input [(ngModel)]="child.otherValue" [attr.aria-label]="child.otherPlaceholder[selectedLanguage]"
               [id]="'other_value_' + child.REVIEW_CRITERIA_GRID_ELEMENT_ID"
               matInput
               [disabled]="isDisabledForm"
               type="text"/>
      </mat-form-field>
    </ng-container>
    <div [style.margin-top]="child?.error[selectedLanguage] ? '-20px' : '0'">
      <span *ngIf="child?.error[selectedLanguage]" class="tableFormFieldError">{{child.error[selectedLanguage] | eCaseTranslate}}</span>
    </div>
  </div>
</ng-template>
