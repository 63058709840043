import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ECaseNumberFormatterPipe, ECaseUtils, PendingChangesGuard, RefreshDataService } from 'synto-common';
import { Platform } from '@angular/cdk/platform';


@Component({
  selector: 'fb-text',
  templateUrl: './fb-text.component.html',
  styleUrls: ['./fb-text.component.scss']
})
export class FbTextComponent implements OnInit, AfterViewInit {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() textAlignment: string;
  @Input() paddingLeft: string;
  @Input() rowNumber;
  @Input() selectedSectionId: string;
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Output() emitOutputEventForSchedulerTable = new EventEmitter<any>();
  @Output() newTitle = new EventEmitter<any>();
  originalMaxlength = 0;
  row: any;
  width;
  isIncreaseMaxLength = true;


  constructor(private translate: TranslateService,
    private refreshDataService: RefreshDataService,
    public dialog: MatDialog,
    private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
    private pendingChangesGuard: PendingChangesGuard,
    public platform: Platform) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.row = {};
  }

  ngOnInit(): void {
    if (!this.textAlignment) {
      this.textAlignment = 'center';
    }
    // if (!this.confChange.textAlignment) {
    //   this.confChange.textAlignment = 'left';
    // }
    if (!this.paddingLeft) {
      this.paddingLeft = '0';
    }
    if (this.confChange.maxlength) {
      this.originalMaxlength = this.confChange.maxlength;
    }
    if (!this.confChange.hasOwnProperty('isNegativeAllowed')) {
      this.confChange.isNegativeAllowed = true;
    }
    // console.log(this.confChange);
    // console.log(this.bphChange);
    if (!(this.bphChange)) {
      this.bphChange = {};
      this.bphChange.value = '';
      this.bphChange.error = {};
      this.bphChange.source = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = '';
    } else {
      if (this.confChange.inputType === 'number' && this.bphChange.value.toString().includes('.') && this.isIncreaseMaxLength) {
        this.isIncreaseMaxLength = false;
        //this.confChange.maxlength = this.confChange.maxlength + 2;
      }
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    console.log(this.bphChange.globalReadOnlyMode);
    /*if (!this.bphChange.paddingBottom) {
      this.bphChange.paddingBottom = '0';
    }*/
    this.bphChange.getValue = (): any => this.bphChange.value;

    if (!this.confChange.inputType) {
      this.confChange.inputType = 'text';
    }

    if (this.confChange) {
      this.confChange.initValue = (currentValue): void => {
        currentValue.value = '';
        currentValue.error = {};
        currentValue.getValue = (): any => this.bphChange.value;
      };
    }

    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (this.confChange.tooltipText) {
      this.confChange.tooltipText[this.selectedLanguage] = ECaseUtils.encodeStringToUTF8String(this.confChange.tooltipText[this.selectedLanguage]);
    }
    this.setWidthOfTextField();
  }

  getTranslatedValue(key): string {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  setWidthOfTextField(): void {
    if (this.confChange.maxNumberOfCharacters > 10 && this.confChange.maxNumberOfCharacters < 50) {
      this.width = '50%';
    } else if (this.confChange.maxNumberOfCharacters <= 10) {
      this.width = '10%';
    } else {
      this.width = '100%';
    }
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
      {
        dialog: this.confChange.dialogText,
        selectedLanguage: this.selectedLanguage
      }
    });
  }

  getTextLength(l): number {
    try {
      if (l.toString().length <= this.confChange.maxlength) {
        return l ? l.toString().length : 0;
      } else {
        return this.confChange.maxlength;
      }
    } catch (e) {
      return 0;
    }
  }

  inputValidation($event): void {
    console.log('333333333333333333');
    console.log($event);
    console.log($event.target.value)
    if (this.confChange.allowOnlyLimitedKeys) {
      if (!($event.keyCode >= 8 && $event.keyCode <= 46)) {
        const permittedKeys = this.confChange.permittedKeys;
        if (!permittedKeys.includes($event.key)) {
          console.log('5555555555555555');
          $event.preventDefault();
        }
      }
    }

    if (this.confChange.inputType === 'number' &&
      this.confChange.allowOnlyNumericValues && $event.keyCode === 69) {

      console.log('66666666666666666');
      $event.preventDefault();
    }

    /*    if(this.confChange.restrictCertainKeys){
          if(!($event.keyCode >= 8 && $event.keyCode <= 46)){
            const restrictedKeys = this.confChange.restrictedKeys;
            if(restrictedKeys.includes($event.key)){
              $event.preventDefault();
            }
          }
        }*/
  }

  changeValueEvent($event): void {
    console.log('6666666666666666')
    console.log($event.target.value)

    // Restrict input to numbers only for number input type
    if (this.confChange.inputType === 'percentage' || this.confChange.inputType === 'number') {
      const input = $event.target as HTMLInputElement;
      const nonNumeric = this.confChange.hasOwnProperty('isNegativeNumberAllowed') && this.confChange['isNegativeNumberAllowed'] ? /[^0-9,.-]/g : /[^0-9,.]/g;
      if (input.value.startsWith(".")) input.value = '' ;
      input.value = input.value.replace(nonNumeric, '');
    }
    // if (this.confChange.inputType === 'percentage' || this.confChange.inputType === 'number') {
    if (this.confChange.hasRealtimeFormatting) {
      this.bphChange.value = $event.target.value;
    }

    if (this.confChange.inputType === 'percentage' || this.confChange.inputType === 'number') {
      const t = $event.target;
      if (t.hasAttribute('maxlength') && ($event.target.value.toString().length > this.confChange.maxlength)) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
        this.bphChange.value = t.value;
      }
      //added to fixed the problem where user type 0- and the field break
      if ((this.bphChange.value).includes('0-')) {
        this.bphChange.value = t.value.slice(1, t.getAttribute('maxlength'));
      }

    }

    if (this.confChange.isSpaceNotAllowed) {
      const t = $event.target;
      this.bphChange.value = ECaseUtils.replaceAllWithoutRegex(t.value.toString(), ' ', '');
    }

    if (this.confChange.isAfterWriteFunctionActive) {
      if (typeof this.confChange.afterWriteFunction === 'function') {
        this.confChange.afterWriteFunction(this.globalPrj, this.globalConf, this, this.bphChange);
      }
    }

    if (this.confChange.countUnit === 'ecase.common.words') {
      if (this.getWordCount(this.bphChange.value) >= this.confChange.maxlength) {
        console.log('Event is', $event);
        this.bphChange.value = $event.target.value.toString().split(' ').slice(0, this.confChange.maxlength).join(' ');
      }
    }

      if (this.confChange.isProjectTitle) {
        if (!this.globalPrj.formData) {
          this.globalPrj.formData = {};
        }
        this.globalPrj.formData['projectTitle'] = this.bphChange.value;
        this.globalPrj.formData['applicationTitle'] = this.bphChange.value;
        this.globalPrj.applicationTitle = this.bphChange.value;
        this.globalPrj.projectTitle = this.bphChange.value;
        this.newTitle.emit(this.bphChange.value);
      }
      if (this.confChange.isprojectNickName) {
        if (!this.globalPrj.formData) {
          this.globalPrj.formData = {};
        }
        this.globalPrj.formData['projectNickname'] = this.bphChange.value;
      }


    this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value);
    if (this.confChange.valueLimit && (this.bphChange.value > this.confChange.valueLimit)) {
      this.bphChange.value = this.confChange.valueLimit;
    }

    if (this.confChange.enableOutputEvent || this.bphChange.enableOutputEvent) {
      this.emitOutputEvent.emit({
        'event': $event,
        'bphChange': this.bphChange,
        'confChange': this.confChange,
        'prj': this.globalPrj,
        'conf': this.globalConf,
        'refreshDataService': this.refreshDataService
      });
      if (this.confChange.updateEventTargetValue) {
        $event.target.value = this.bphChange.value;
      }
    }
    if (this.confChange.enableOutputEventForSchedulerTable) {
      this.emitOutputEventForSchedulerTable.emit(this.bphChange);
    }
    this.pendingChangesGuard.isPristine = false;
    if (this.confChange.hasRealtimeFormatting || this.confChange.inputType === 'percentage' || this.confChange.inputType === 'number') {
      this.bphChange.value = this.bphChange.value.replace(',', '.');
    }
    console.log(this.bphChange.value);
    // }
  }


  getAllInputs(): void {
    if (!this.isValidHtml) {
      setTimeout(() => {
        if (document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber)) {
          const documentSelectField = document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber).getElementsByClassName('mat-form-field-wrapper');
          const size = documentSelectField.length;
          for (let i = 0; i < size; i++) {
            documentSelectField.item(i).setAttribute('style', 'padding:0;');
          }
          this.adjustInputSuffix('td_' + this.confChange.name + '_' + this.rowNumber);
        }
      }, 100);
    } else {
      setTimeout(() => {
        if (document.getElementById(this.selectedSectionId + '_' + this.confChange.name)) {
          this.adjustInputSuffix(this.selectedSectionId + '_' + this.confChange.name);
        }
      }, 100);
    }
  }

  adjustInputSuffix(id: string): void {
    if (this.confChange.inputSuffix) {
      const suffixElements = document.getElementById(id).getElementsByClassName('mat-form-field-suffix');
      const prefixElements = document.getElementById(id).getElementsByClassName('mat-form-field-prefix');

      console.log('Suffix Elements', suffixElements);
      console.log('Prefix Elements', prefixElements);
      if (suffixElements.length > 0) {
        for (let i = 0; i < suffixElements.length; i++) {
          suffixElements.item(i).setAttribute('style', 'top:0;');
        }
      }

      if (prefixElements.length > 0) {
        for (let i = 0; i < prefixElements.length; i++) {
          prefixElements.item(i).setAttribute('style', 'top:0;');
        }
      }
    }
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  getWidthFromMaxlength(isInsideTable?): string {
    if (isInsideTable) {
      if (this.confChange.maxlength <= 5) {
        return '30%';
      } else if (this.confChange.maxlength > 5 && this.confChange.maxlength < 10) {
        return '50%';
      } else {
        return '100%';
      }
    } else {
      if (this.confChange.maxlength && !(this.platform.ANDROID || this.platform.IOS) && this.confChange.countUnit !== 'ecase.common.words') {
        if (this.confChange.maxlength <= 10) {
          return '15%';
        } else if (this.confChange.maxlength > 10 && this.confChange.maxlength <= 50) {
          return '50%';
        } else if (this.confChange.maxlength > 50) {
          return '100%';
        }
      } else {
        return '100%';
      }
    }
  }

  getWidthForCounter(): any {
    if (this.confChange.blockWidthPerct) {
      const calculateDifferenceOfWidth = (100 - (+(this.confChange.blockWidthPerct))) / 2;
      return (100 - calculateDifferenceOfWidth) + '%';
    } else if (this.confChange.blockWidthPx) {
      return (this.confChange.blockWidthPx) + 'px';
    } else {
      return '';
    }
  }


  /* validateNumberField($event, type){
     console.log($event);
     const charCode = ($event.which) ? $event.which : $event.keyCode;
     console.log(charCode);
     console.log(type);
     if(type === 'number' || type === 'percentage'){
       if ((charCode >= 48 && charCode <= 57) || charCode === 46 || charCode === 101){
         return true;
       }
       else{
         return false;
       }
     }

   }*/

  ngAfterViewInit(): void {
    this.getAllInputs();
  }

  getBphValue(): any {
    if (this.bphChange.value) {
      if (this.confChange.currency === 'EUR') {
        return this.confChange.isFormattingDisabled
          ? this.bphChange.value
          : this.eCaseNumberFormatterPipe.transform(this.bphChange.value, this.confChange.currency, '', true, 'EUR', this.confChange.isDecimalNotAllowed ? 0 : (this.confChange.decimalPlacesAllowed || this.confChange.decimalPlacesAllowed === 0) ? this.confChange.decimalPlacesAllowed : 2);
      }
      return this.confChange.isFormattingDisabled
        ? this.bphChange.value
        : this.eCaseNumberFormatterPipe.transform(this.bphChange.value, this.confChange.currency, null, this.confChange.isDecimalNotAllowed, null, this.confChange.decimalPlacesAllowed);
    } else { return ''; }
  }
}
