import {
  AfterContentInit,
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {ECaseUtils, ECaseUtilsGlobal, PendingChangesGuard, RefreshDataService} from 'synto-common';


@Component({
  selector: 'fb-select',
  templateUrl: './fb-select.component.html',
  styleUrls: ['./fb-select.component.scss']
})
export class FbSelectComponent implements OnInit, AfterViewInit, DoCheck, AfterContentInit {
  @Input() url: any;
  @Input() selectedLanguage: any;
  @Input() selectedSectionId: string;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalPrj: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() rowNumber;
  @Output() emitOutputEvent = new EventEmitter<any>();
  isValid: boolean;
  lang: any;
  searchResult = [];
  row: any;
  list: any[] = [];
  dataSqlList = [];
  otherValue = {'label': {}, 'value': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID}; // Labels are added in ngOnInit method
  differ: KeyValueDiffer<string, any>;


  constructor(private translate: TranslateService,
              private refreshDataService: RefreshDataService,
              private pendingChangesGuard: PendingChangesGuard,
              private differs: KeyValueDiffers,
              public dialog: MatDialog) {
    this.confChange = {};
    this.isValid = false;
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    // This is default value four otherMaxLenght as discussd  with Faissal
    if (!this.confChange.otherMsxlength) {
      this.confChange.otherMsxlength = 4000;
    }
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.bphChange.listName = this.confChange.listName;
    this.bphChange.error = {};
    this.row = {};
    this.differ = this.differs.find({}).create();
  }

  removeItemsToHide(list: any[]): any[] {
    if (this.confChange.lovItemsToHide && this.confChange.lovItemsToHide.length > 0) {
      return list.filter(item => !this.confChange.lovItemsToHide.includes(item.value));
    } else {
      return list;
    }
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }

  isSortingKeySame(list: any[]): boolean {
    return list.every(item => item.sortingKey === list[0].sortingKey);
  }


  isOtherValueSelected(): boolean {
    return (this.bphChange.value.value === this.otherValue.value || this.searchResult.filter(item => this.bphChange.value.value === item.value && item.isOtherItem).length > 0);
  }

  ngDoCheck(): void {
    const change = this.differ.diff(this.bphChange);
    if (change) {
      change.forEachChangedItem((item) => {
        if (item.key === 'hide' && item.currentValue === false) {
          if (this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode === 'true') {
            this.hideSelectArrow();
          }
        }
      });
    }
  }

  getTextLength(l): number {
    try {
      if (l.toString().length <= this.confChange.otherMsxlength) {
        return l ? l.toString().length : 0;
      } else {
        return this.confChange.otherMsxlength;
      }
    } catch (e) {
      return 0;
    }
  }

  changeInputValueEvent($event): void {
    console.log((($event.target.value).toString()).length);
    const t = $event.target;
    if ((($event.target.value).toString()).length > this.confChange.otherMsxlength) {
      t.value = t.value.slice(0, this.confChange.otherMsxlength);
      this.bphChange.otherValue = t.value;
    }
    console.log("<>>>>>>>>>>>>>>>>>>>>>>>")
    if (this.confChange.isEnableOutputEvent || this.confChange.enableOutputEvent) {
      this.emitOutputEvent.emit({
        'event' : $event,
        'bphChange': this.bphChange,
        'confChange': this.confChange,
        'otherValue': this.otherValue,
        'prj': this.globalPrj,
        'conf': this.globalConf,
        'refreshDataService': this.refreshDataService
      });
    }
  }


  disableOption(noDuplicateOpt, currentOptionValue): any {
    if (!noDuplicateOpt) {
      return false;
    }
    const confChange = '_CONF__CHANGE_';
    const res = confChange.split('.');
    let tablePrj = 'globalPrj.' + res[2] + '.' + res[3]; // Ignore error. Variable is actually being reassigned in eval
    const colName = res[4];
    eval('tablePrj = ' + tablePrj);
    let optionValue;
    eval(' for(let i = 0; i < tablePrj.rows.length; i++){\n' +
      '      optionValue = tablePrj.rows[i].colName.value.value;\n' +
      '      if(currentOptionValue === optionValue){\n' +
      '        return true;\n' +
      '      }\n' +
      '    }');

    return false;

  }

  hideSelectArrow(): void {
    setTimeout(() => {
      if (document.getElementById(this.selectedSectionId + '_' + this.confChange.name)) {
        const documentSelectField = document.getElementById(this.selectedSectionId + '_' + this.confChange.name).getElementsByClassName('mat-select-arrow');
        const size = documentSelectField.length;
        for (let i = 0; i < size; i++) {
          documentSelectField.item(i).setAttribute('style', 'color:transparent;');
        }
      }
      if (document.getElementById(this.selectedSectionId + '_' + this.confChange.name + '_' + this.rowNumber)) {
        const documentSelectField = document.getElementById(this.selectedSectionId + '_' + this.confChange.name + '_' + this.rowNumber).getElementsByClassName('mat-select-arrow');
        const size = documentSelectField.length;
        for (let i = 0; i < size; i++) {
          documentSelectField.item(i).setAttribute('style', 'color:transparent;');
        }
      }
    }, 10);
  }

  removeHeightConstraint(): void {
    if (this.confChange.isSelectScrollDisabled) {
      setTimeout(() => {
        const container = document.getElementsByClassName('cdk-overlay-pane');
        const size = container.length;
        for (let i = 0; i < size; i++) {
          const documentSelectField = container.item(i).getElementsByClassName('mat-select-panel');
          for (let j = 0; j < documentSelectField.length; j++) {
            documentSelectField.item(j).setAttribute('style', 'max-height:fit-content;');
          }
        }
      }, 10);
    }
  }


  change(nv, c, s): void {
    console.log(nv);
    console.log(c);
    console.log(s);
    s.value = {};
    s.value.value = nv;
    if (nv === '') {
      s.value.label = {};
      return;
    }

    let searchTerm;

    if (c.lovSQL) {
      searchTerm = c.lovSQL.name;
    } else {
      searchTerm = c.listName;
    }

    try {
      if (searchTerm) {
        let list;
        if (c.lovSQL && !c.lovSQL.isLov) {
          list = this.dataSqlList;
        } else if (this.confChange.isDynamicList) {
          list = this.bphChange.dynamicList;
        } else if (this.bphChange.list) {
          list = this.bphChange.list;
        } else {
          list = this.globalConf.lovs[searchTerm].list;
        }

        list.forEach((e) => {
          if (e.value === nv) {
            s.value.label = e.label;
            s.value.source = e.source;
            s.value.source_id = e.source_id;
            s.value.data = e.data;
            s.value.parentTermId = e.parentTermId;
            s.value.is_other = e.is_other;
            this.updateSubListComponent(c.subList, e.subList);
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
    if (this.isOtherValueSelected()) {
      s.value.isOtherItem = true;
    } else {
      s.value.isOtherItem = false;
    }
    if (c && c.executeOnChange) {
      this.confChange.executeOnChange(this.globalConf, this.globalPrj, this.selectedLanguage, this.rowNumber, nv);
    }
    this.changeValueEvent();
    console.log(this.bphChange);
    console.log(this.confChange);
  }

  updateSubListComponent(subListName, subList, reset = true): void {
    if (subListName) {
      const data: any = {};
      let subComponent: any = {};
      if (this.rowNumber === undefined) {
        subComponent = this.globalPrj[subListName.split('.')[0]][subListName.split('.')[1]];
      } else if (this.rowNumber >= 0 && this.confChange.selectedContainerType === 'Table') {
        data.rowNumber = this.rowNumber;
        subComponent = this.globalPrj[subListName.split('.')[0]][this.confChange.selectedContainerName].rows[this.rowNumber][subListName.split('.')[1]];
      }
      subComponent.listName = subList ? subList : 'empty_list';
      if (reset) {
        subComponent.value = {};
        subComponent.value.value = '';
        subComponent.value.label = {};
      }
      data.subListComponent = this.confChange.subList;
      //HERE, trying to disable when list is empty, probs mieux de le faire en html.
      //aussi rendre les list des sub-select à emptyList
      data.newListName = subList ? subList : 'empty_list';
      this.refreshDataService.setNewValueForSelect(data);
    }
  }

  updateEmpty(): void {
    if (this.searchResult.length === 0) {
      this.bphChange.isEmpty = true;
    } else {
      this.bphChange.isEmpty = false;
    }
  }

  getTranslatedValue(key): string {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  ngOnInit(): void {
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.value = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = {};
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }


    console.log('<===========================================================');
    if (!this.confChange.otherMsxlength) {
      this.confChange.otherMsxlength = 4000;
    }
    console.log(this.confChange.otherMsxlength);
    this.refreshDataService.getNewValueForSelect().subscribe((data) => {
      if (data.subListComponent && data.subListComponent.split('.')[1] === this.confChange.name) {
        if (this.rowNumber === undefined) {
          this.confChange.listName = data.newListName;
          if (this.globalConf.lovs[this.confChange.listName]) {
            this.list = this.globalConf.lovs[this.confChange.listName].list;
            this.list = this.list.sort((a, b) => (a.sortingKey > b.sortingKey
              || (a.sortingKey === b.sortingKey && a.label[this.selectedLanguage] > b.label[this.selectedLanguage]))
              ? 1 : ((a.sortingKey < b.sortingKey || b.label[this.selectedLanguage] > a.label[this.selectedLanguage]) ? -1 : 0));
            // this.searchResult = this.globalConf.lovs[this.confChange.listName].list;
            console.log(this.list);
            this.searchResult = this.list;
            this.updateEmpty();
            if (this.searchResult.includes(item => item.value === this.bphChange.value.value)) {
              this.bphChange.value = {};
            }
          } else {
            this.searchResult = [];
            this.updateEmpty();
          }
        } else if (this.rowNumber >= 0 && this.rowNumber === data.rowNumber) {
          this.confChange.listName = data.newListName;
          if (this.globalConf.lovs[this.confChange.listName]) {
            this.list = this.globalConf.lovs[this.confChange.listName].list;
            this.list = this.list.sort((a, b) => (a.sortingKey > b.sortingKey
              || (a.sortingKey === b.sortingKey && a.label[this.selectedLanguage] > b.label[this.selectedLanguage]))
              ? 1 : ((a.sortingKey < b.sortingKey || b.label[this.selectedLanguage] > a.label[this.selectedLanguage]) ? -1 : 0));
            // this.searchResult = this.globalConf.lovs[this.confChange.listName].list;
            console.log(this.list);
            this.searchResult = this.list;
            this.updateEmpty();
            if (this.searchResult.includes(item => item.value === this.bphChange.value.value)) {
              this.bphChange.value = {};
            }
          } else {
            this.searchResult = [];
            this.updateEmpty();
          }
        }
      } else {
        if (data && (data.name === this.confChange.name) && this.bphChange.list) {
          this.list = this.bphChange.list;
          this.list = this.list.sort((a, b) => (a.sortingKey > b.sortingKey
            || (a.sortingKey === b.sortingKey && a.label[this.selectedLanguage] > b.label[this.selectedLanguage]))
            ? 1 : ((a.sortingKey < b.sortingKey || b.label[this.selectedLanguage] > a.label[this.selectedLanguage]) ? -1 : 0));
          this.searchResult = this.list;
        } else if (data && (data.name === this.confChange.name)) {
          if (this.bphChange.value.value === this.otherValue.value) {
            this.getSelectFields();
          }
        }
      }
       if(data && (data.name === this.confChange.name) && data.value) {
         this.bphChange.value = data.value;
         this.changeValueEvent();
      }
      console.log('===========================================================>');

    });
    if (this.globalConf.lovs[this.confChange.listName] || this.bphChange.list) {
      this.list = this.bphChange.list ? this.bphChange.list : this.globalConf.lovs[this.confChange.listName].list;
      this.list = this.list.sort((a, b) => {
        const x = a.label[this.selectedLanguage];
        const y = b.label[this.selectedLanguage];
        return x.localeCompare(y);
      });
      this.searchResult = this.list;
      this.updateEmpty();
    }

    if (!this.bphChange) {
      this.bphChange = {};
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (this.bphChange.value === '' || !this.bphChange.value) {
      this.bphChange.value = {};
    }

    this.confChange.getValue = (currentValue, lang): string => {
      if (currentValue.value && currentValue.value.label) {
        return currentValue.value.label[lang];
      } else {
        return '';
      }
    };
    this.confChange.initValue = (currentValue, localConf): void => {
      currentValue.value = {};
      currentValue.error = {};
      currentValue.listName = localConf.listName;
      currentValue.getValue = (): any => this.bphChange.value.value;
    };


    try {
      if (this.confChange.lovSQL) {
        if (this.globalConf.lovs[this.confChange.lovSQL.name]) {
          this.searchResult = this.searchResult.concat(this.globalConf.lovs[this.confChange.lovSQL.name].list);
        } else {
          this.searchResult = this.globalPrj.formData[this.confChange.lovSQL.name].data.map((item) => {
            const obj: any = {};
            obj.label = {};
            Object.keys(item).filter(key => key.startsWith('LABEL_')).forEach((key) => {
              obj.label[key.replace('LABEL_', '').toLowerCase()] = item[key];
            });
            obj.value = item['VALUE'];
            return obj;
          });
          this.dataSqlList = ECaseUtils.eCaseCloneDeep(this.searchResult);
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.translate.langs.forEach((lang) => {
      if (lang && lang !== 'undefined') {
        this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
      }
    });

    if (this.isSortingKeySame(this.list)) {
      this.searchResult = this.searchResult.sort((a, b) => a.label[this.selectedLanguage].localeCompare(b.label[this.selectedLanguage]));
    } else {
      this.searchResult = this.searchResult.sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
    }

  if (this.confChange.isEnableOutputEvent || this.confChange.enableOutputEvent) {
    this.emitOutputEvent.emit({
      'bphChange': this.bphChange,
      'confChange': this.confChange,
      'otherValue': this.otherValue,
      'prj': this.globalPrj,
      'conf': this.globalConf,
      'refreshDataService': this.refreshDataService
    });
  }
  this.updateEmpty();

  this.searchResult.forEach((e) => {
    if (e.value === this.bphChange.value.value) {
      this.updateSubListComponent(this.confChange.subList, e.subList, false);
    }
  });
}


changeValueEvent(): void {
  this.pendingChangesGuard.isPristine = false;
  this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value);
  console.log(this.confChange);
  console.log("change value event select")
  if (this.confChange.isEnableOutputEvent || this.confChange.enableOutputEvent) {
    const event: any = {};
    event.prj = this.globalPrj;
    event.confChange = this.confChange;
    event.conf = this.globalConf;
    event.bphChange = this.bphChange;
    event.otherValue = this.otherValue;
    event.refreshDataService = this.refreshDataService;
    this.emitOutputEvent.emit(event);
  }
  if (this.bphChange.value.value === this.otherValue.value) {
    this.getSelectFields();
  }
}

  getValue(currentValue, lang): any {
    if (currentValue.value && currentValue.value.label) {
      return currentValue.value.label[lang];
    } else {
      return '';
    }
  }

  getSelectFields(): void {
    if (!this.isValidHtml) {
      setTimeout(() => {
        if (document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber)) {
          const documentSelectField = document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber).getElementsByClassName('mat-form-field-wrapper');
          const size = documentSelectField.length;
          for (let i = 0; i < size; i++) {
            documentSelectField.item(i).setAttribute('style', 'padding:0;');
          }
        }
      }, 100);
    }
  }

  ngAfterViewInit(): void {
    this.getSelectFields();
    if (this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode === 'true') {
      this.hideSelectArrow();
    }
  }

  ngAfterContentInit(): void {
    this.searchResult.forEach((e) => {
      if (e.value === this.bphChange.value.value) {
        this.updateSubListComponent(this.confChange.subList, e.subList, false);
      }
    });
  }

}
