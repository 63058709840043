<mat-dialog-content>
  <div>
    <mat-label><span style="color:red">* </span>{{'ecase.common.select.document' | translate}}</mat-label>
    <mat-form-field appearance="outline" class="ecase-input-full-width">
      <mat-select [(value)]="selectedDocument" id="documents" [attr.arial-label]="'ecase.common.select.document' | translate"
                  required>
        <mat-option [value]="">{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
        <mat-option *ngFor="let document of documents" [value]="document.value">{{document.label[lang]}} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button (click)="onYesClick()" [disabled]="!selectedDocument"
          class="eCase-dialog-positive-action-button" mat-raised-button
          style=" margin-right: 15px;">{{'ecase.common.confirm'| translate }}
  </button>
  <button (click)="onNoClick()"
          class="eCase-dialog-negative-action-button"
          mat-raised-button>{{'ecase.common.close'| translate }}
  </button>
</mat-dialog-actions>
