import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {DefaultLangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
  EcaseHttpService,
  ECaseSnackBarService,
  ECaseUtilsGlobal,
  LovDataService,
  ParametersService
} from 'synto-common';
import googleLibPhoneNumber from 'google-libphonenumber';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {FormControl} from '@angular/forms';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {
  CdkOverlayOrigin,
  ConnectedPosition,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayConfig
} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {OverlayRef} from '@angular/cdk/overlay/overlay-ref';
import {MatSelectionListChange} from '@angular/material/list';

/**
 * Created by sahilb@evision.ca on 2021-08-29.
 */
@Component({
  selector: 'ecase-phone-number-field',
  templateUrl: './ecase-phone-number-field.component.html',
  styleUrls: ['./ecase-phone-number-field.component.scss']
})
export class EcasePhoneNumberFieldComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() e164PhoneNumberFormat: string;
  @Input() countries: any[];
  /*
  Output will be a json object containing 'value' and 'errorMessage' keys
  Example of output for valid phone numbers
  {
      'value': true,
      'errorMessage': ''
   }
  Example of output for invalid phone numbers
  {
      'value': false,
      'errorMessage': 'something bad happened'
   }
  */
  @Output() isPhoneNumberValid = new EventEmitter<any>();
  /*
  Output will be a json object containing 'originalFormat', 'nationalFormat' and 'e164Format' keys
  If input is invalid then output will be empty object {}
  Example of valid output for country 'Canada' and number 5141234567
  {
    'originalFormat': '+1 514-123-4567',
    'nationalFormat': '(514) 123-4567',
    'e164Format': '+15141234567',
  }
  */
  @Output() phoneNumberValue = new EventEmitter<any>();
  @ViewChild('overlayTemplate', {read: TemplateRef}) element: TemplateRef<any>;
  @ViewChild('cdkOverlayOrigin') cdkOverlayOrigin: CdkOverlayOrigin;
  selectedLanguage = '';
  countryText = new FormControl('', []);
  filteredCountries: Observable<any[]>;
  selectedCountry: any;
  private countriesWithMetaData: any[] = [];
  private phoneNumberUtil;
  private overlayConfig: OverlayConfig;
  private templatePortal: TemplatePortal;
  private overlayRefs: OverlayRef[] = [];
  private _phoneNumberCtrl: FormControl;
  private _countryCtrl: FormControl;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private translateService: TranslateService, private lovDataService: LovDataService,
              private eCaseHttpService: EcaseHttpService, private eCaseSnackBarService: ECaseSnackBarService,
              private overlay: Overlay, private viewContainer: ViewContainerRef, private parametersService: ParametersService) {
  }

  get countryCtrl(): FormControl {
    return this._countryCtrl;
  }

  @Input()
  set countryCtrl(countryCtrl: FormControl) {
    if (!countryCtrl) {
      countryCtrl = new FormControl();
    }
    this._countryCtrl = countryCtrl;
  }


  get phoneNumberCtrl(): FormControl {
    return this._phoneNumberCtrl;
  }

  @Input()
  set phoneNumberCtrl(phoneNumberCtrl: FormControl) {
    if (!phoneNumberCtrl) {
      phoneNumberCtrl = new FormControl();
    }
    this._phoneNumberCtrl = phoneNumberCtrl;
  }

  ngOnInit(): void {
    if (!this._phoneNumberCtrl) {
      this._phoneNumberCtrl = new FormControl();
    }
    if (!this._countryCtrl) {
      this._countryCtrl = new FormControl();
    }
    this.phoneNumberUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
    this.selectedLanguage = this.translateService.getDefaultLang();
    this.translateService.onDefaultLangChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params: DefaultLangChangeEvent) => {
        this.selectedLanguage = params.lang;
      });
    if (this.countries) {
      this.countries = this.countries.map((e) => {
        if (e.value && !e.label) {
          e.label = e.value;
        }
        return e;
      }).filter(e => e.label && e.label[this.selectedLanguage]).sort((a, b) => {
        if (a.sortingKey > b.sortingKey) {
          return 1;
        } else if (a.sortingKey < b.sortingKey) {
          return -1;
        } else {
          return 0;
        }
      });
      this.getCountriesMetaData();
    } else {
      this.lovDataService.getAllTermsForSelectedTaxonomy(ECaseUtilsGlobal.COUNTRY_TAXONOMY_ID).subscribe((result: any) => {
        this.countries = result.data.allTerms.filter(e => e.label && e.label[this.selectedLanguage]).sort((a, b) => {
          if (a.sortingKey > b.sortingKey) {
            return 1;
          } else if (a.sortingKey < b.sortingKey) {
            return -1;
          } else {
            return 0;
          }
        });
        this.getCountriesMetaData();
      });
    }

    this.filteredCountries = this.countryText.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterCountries(state) : this.countries.slice()),
        takeUntil(this._unsubscribeAll)
      );
    this.phoneNumberCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        if (value !== '' && isNaN(value)) {
          this.phoneNumberCtrl.setValue('');
        }
        if (this.countryCtrl.value && this.countryCtrl.value.length > 0) {
          this.isPhoneNumberValid.emit(this.validatePhoneNumber(this.countryCtrl.value[0]));
        } else {
          this.isPhoneNumberValid.emit(this.validatePhoneNumber(undefined));
        }
      });
  }

  getCountriesMetaData(): void {
    this.eCaseHttpService.get('/api/getCountriesJson').subscribe({
      next: (response) => {
        this.countriesWithMetaData = response;
        this.addMetaDataToCountries();
      },
      error: () => {
        this.eCaseHttpService.get('https://restcountries.eu/rest/v2/all').subscribe({
          next: (_response) => {
            this.countriesWithMetaData = _response;
            this.addMetaDataToCountries();
          },
          error: (_err) => {
            this.eCaseSnackBarService.show('failure', _err);
          }
        });
      }
    });
  }

  addMetaDataToCountries(): void {
    this.countries = this.countries.map((country) => {
      let countryName;
      if (country.label['en']) {
        countryName = country.label['en'].toLowerCase();
      } else {
        countryName = country.label[Object.keys(country.label)[0]].toLowerCase();
      }
      const _country = this.countriesWithMetaData.filter(e => ((e.name && e.name.toLowerCase().startsWith(countryName))
        || (e.altSpellings && e.altSpellings.some(_e => _e.toLowerCase().startsWith(countryName)))));
      if (_country.length > 0) {
        country.callingCode = _country[0].callingCodes[0];
        country.countryCode = _country[0].alpha2Code.toLowerCase();
      } else {
        country.callingCode = '';
        country.countryCode = '';
      }
      return country;
    });
    this.updateExistingFields();
  }

  createOverlay(): void {
    const overlayRef = this.overlay.create(this.overlayConfig);
    overlayRef.attach(this.templatePortal);
    this.overlayRefs.push(overlayRef);
  }

  updateExistingFields(): void {
    if (this.e164PhoneNumberFormat) {
      const result = {
        'value': true,
        'errorMessage': ''
      };
      try {
        const googlePhoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(this.e164PhoneNumberFormat);
        if (!(this.phoneNumberUtil.isValidNumber(googlePhoneNumber) && this.phoneNumberUtil.isPossibleNumber(googlePhoneNumber))) {
          result.errorMessage = 'ecase.common.country.invalid.phone.number.entered';
          result.value = false;
          this.phoneNumberValue.emit({});
        } else {
          const obj = {
            'originalFormat': this.phoneNumberUtil.formatInOriginalFormat(googlePhoneNumber, this.phoneNumberUtil.getRegionCodeForNumber(googlePhoneNumber)),
            'nationalFormat': this.phoneNumberUtil.format(googlePhoneNumber, googleLibPhoneNumber.PhoneNumberFormat.NATIONAL),
            'e164Format': this.phoneNumberUtil.format(googlePhoneNumber, googleLibPhoneNumber.PhoneNumberFormat.E164),
          };
          this.phoneNumberValue.emit(obj);
          this.selectedCountry = this.countries.filter(e => e.countryCode === this.phoneNumberUtil.getRegionCodeForNumber(googlePhoneNumber)
            .toLowerCase())[0];
          this._countryCtrl.setValue([this.selectedCountry]);
          this.countryText.setValue(this.selectedCountry.label[this.selectedLanguage]);
          this._phoneNumberCtrl.setValue(googlePhoneNumber.getNationalNumber().toString());
        }
      } catch (e) {
        result.errorMessage = e.toString();
        result.value = false;
        console.error(e);
        this.phoneNumberValue.emit({});
      }
    } else {
      const defaultCountry = this.parametersService.parameter['/core/ui/default_country_id'];
      if (defaultCountry) {
        this.selectedCountry = this.countries.filter(e => e.id === Number(defaultCountry))[0];
        this._countryCtrl.setValue([this.selectedCountry]);
        this.countryText.setValue(this.selectedCountry.label[this.selectedLanguage]);
      }
      this.phoneNumberValue.emit({});
      this.isPhoneNumberValid.emit(false);
    }
  }

  ngAfterViewInit(): void {
    this.overlayConfig = this.getOverlayConfig();
    this.templatePortal = new TemplatePortal(this.element, this.viewContainer, {
      $implicit: this
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }

  updatePhoneNumberFormat(event: MatSelectionListChange): void {
    if (event.options.length > 0) {
      const country = event.options[0].value;
      if (this.phoneNumberCtrl.value) {
        this.isPhoneNumberValid.emit(this.validatePhoneNumber(country));
      }
      this.selectedCountry = country;
    }
    this.closeOverlay();
  }

  selectionClicked(): void {
    if (this.phoneNumberCtrl.value && this.selectedCountry) {
      this.isPhoneNumberValid.emit(this.validatePhoneNumber(this.selectedCountry));
    }
    this.closeOverlay();
  }

  closeOverlay(): void {
    this.overlayRefs.forEach((overlayRef) => {
      overlayRef.dispose();
    });
    this.overlayRefs = [];
  }

  validatePhoneNumber(country: any): any {
    const result = {
      'value': true,
      'errorMessage': ''
    };
    if (!country) {
      result.errorMessage = 'ecase.common.country.not.selected';
      result.value = false;
      this.phoneNumberValue.emit({});
      return result;
    }
    const phoneNumber = '+' + country.callingCode + this.phoneNumberCtrl.value;
    try {
      const googlePhoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
      if (!(this.phoneNumberUtil.isValidNumber(googlePhoneNumber) && this.phoneNumberUtil.isPossibleNumber(googlePhoneNumber))) {
        result.errorMessage = 'ecase.common.country.invalid.phone.number.entered';
        result.value = false;
        this.phoneNumberValue.emit({});
      } else {
        const obj = {
          'originalFormat': this.phoneNumberUtil.formatInOriginalFormat(googlePhoneNumber, this.phoneNumberUtil.getRegionCodeForNumber(googlePhoneNumber)),
          'nationalFormat': this.phoneNumberUtil.format(googlePhoneNumber, googleLibPhoneNumber.PhoneNumberFormat.NATIONAL),
          'e164Format': this.phoneNumberUtil.format(googlePhoneNumber, googleLibPhoneNumber.PhoneNumberFormat.E164),
        };
        this.phoneNumberValue.emit(obj);
      }
    } catch (e) {
      result.errorMessage = e.toString();
      result.value = false;
      console.error(e);
      this.phoneNumberValue.emit({});
    }
    return result;
  }

  displayFn(country: any): string | undefined {
    const label = this.countries.filter(item => item.id === country.id).map(item => item.label[this.selectedLanguage] + ' (+' + item.callingCode + ')')[0];
    return label ? label : undefined;
  }

  private getOverlayConfig(): OverlayConfig {
    const origin: FlexibleConnectedPositionStrategyOrigin = this.cdkOverlayOrigin.elementRef;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      } as ConnectedPosition])
      .withPush(false);

    return new OverlayConfig({
      hasBackdrop: false,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy,
      disposeOnNavigation : true
    });
  }

  private _filterCountries(value: string | number): any[] {
    const filterValue = value.toString().toLowerCase();
    return this.countries.filter(country => country.label[this.selectedLanguage].toLowerCase().includes(filterValue) || country.callingCode.includes(filterValue));
  }

}
