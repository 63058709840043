import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  EcaseHttpService,
  ECaseSnackBarService,
  ECaseUtils,
  ECaseUtilsGlobal,
  LovDataService,
  PendingChangesGuard, RefreshDataService
} from 'synto-common';
import {Platform} from '@angular/cdk/platform';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'fb-review-criteria-grid',
  templateUrl: './fb-review-criteria-grid.component.html',
  styleUrls: ['./fb-review-criteria-grid.component.scss']
})
export class FbReviewCriteriaGridComponent implements OnInit {
  @Input() selectedLanguage: any;
  @Input() selectedSection: string;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() rowNumber;
  @Input() offlineModeEnabled;
  @Input() globalReadOnlyMode: boolean;
  @Input() selectedSectionId: string;
  @Output() emitOutputEvent = new EventEmitter<any>();
  originalDataArray = [];
  lovsToBeRetrieved = [];
  otherValue = {'label': {}, 'value': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID}; // Labels are added in ngOnInit method
  interLevelPaddingLeft = 15;
  computedScore;
  othersReviewersScores;
  othersReviewersMaxScore;
  othersReviewersAvgScore;
  isDisabledForm: boolean;
  isReviewCommentDeadlinePassed: boolean;

  constructor(public translate: TranslateService,
              private eCaseHttpService: EcaseHttpService,
              private pendingChangesGuard: PendingChangesGuard,
              private lovDataService: LovDataService,
              private eCaseSnackBarService: ECaseSnackBarService,
              private refreshDataService: RefreshDataService,
              public platform: Platform) {
    this.confChange = {};
    this._bphChange = {};
    this._bphChange.rows = [];
    this._bphChange.error = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
  }

  _bphChange: any;

  get bphChange(): any {
    return this._bphChange;
  }

  @Input()
  set bphChange(value: any) {
    this._bphChange = value;
    if (!this._bphChange) {
      this._bphChange = {};
    }
    if (!this._bphChange.error) {
      this._bphChange.error = {};
    }
    if (!this._bphChange.rows || this._bphChange.rows.length === 0) {
      this._bphChange.rows = [];
      this.createGridStructure();
    } else {
      this._bphChange.rows = this.adjustOrderByWeight(this._bphChange.rows, null);
    }
    if (this.globalPrj) {
      this.globalPrj[this.selectedSectionId][this.confChange.name] = this._bphChange;
      this.refreshDataService.tableCancelClicked(this.globalPrj);
    }
  }

  ngOnInit(): void {
    this.translate.langs.forEach((lang) => {
      if (lang && lang !== 'undefined') {
        this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
      }
    });
    if (!this._bphChange) {
      this._bphChange = {};
    }
    if (!this._bphChange.rows) {
      this._bphChange.rows = [];
    }
    if (!this._bphChange.error) {
      this._bphChange.error = {};
    }
    if (this._bphChange.rows.length > 0) {
      this.originalDataArray = cloneDeep(this._bphChange.rows);
    }
    this.createGridStructure();
    this.retrieveLovs();
    this.retrieveOriginalData();
    this.globalPrj[this.selectedSectionId][this.confChange.name] = this._bphChange;
    this.refreshDataService.tableCancelClicked(this.globalPrj);
  }

  createGridStructure() {
    this.isDisabledForm = this.globalPrj && this.globalPrj.isDisabledForm;
    if (this.globalPrj && this.globalPrj.hasOwnProperty('isReviewCommentDeadlinePassed')) {
      this.isReviewCommentDeadlinePassed = this.globalPrj.isReviewCommentDeadlinePassed;
    }
    if (this.globalPrj && this.globalPrj.formData && this.globalPrj.formData['REVIEW_GRID_DATA'] && this.globalPrj.formData['REVIEW_GRID_DATA'].data && this.globalPrj.formData['REVIEW_GRID_DATA'].data.length > 0) {
      this._bphChange.rows = [];
      let _rows = [];
      this.globalPrj.formData['REVIEW_GRID_DATA'].data.forEach((row) => {
        if (row['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] && _rows.filter(child => child.child['REVIEW_CRITERIA_GRID_ELEMENT_ID'] === row['REVIEW_CRITERIA_GRID_ELEMENT_ID']).length === 0) {
          const children = this.globalPrj.formData['REVIEW_GRID_DATA'].data
            .filter(__row => __row['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] === row['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'])
            .sort((a, b) => {
              if (Number(a['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT']) < Number((b['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT']))) {
                return -1;
              } else if (Number(a['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT']) > Number(b['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT'])) {
                return 1;
              }
              return 0;
            }).map((child, _index) => {
              child.value = '';
              if (child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_LOV_ID']) {
                this.lovsToBeRetrieved.push(child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_LOV_ID']);
                if (child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_DEFAULT_LOV_ITEM_ID']) {
                  child.value = {};
                  child.value.value = Number(child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_DEFAULT_LOV_ITEM_ID']);
                }
              }
              child.error = {};
              child.child = cloneDeep(child);
              const _child: any = {};
              _child['REVIEW_CRITERIA_GRID_ELEMENT_TYPE'] = child['REVIEW_CRITERIA_GRID_ELEMENT_TYPE'];
              _child['REVIEW_CRITERIA_GRID_ELEMENT_METADATA_IS_ANONYMIZED'] = child['REVIEW_CRITERIA_GRID_ELEMENT_METADATA_IS_ANONYMIZED'] === "1";
              _child.child = cloneDeep(child);
              if (child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION) {
                _child.child['REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION'] = {};
                this.translate.langs.forEach((lang) => {
                  _child.child['REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION'][lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION, lang);
                });
              }
              return _child;
            });
          children[children.length - 1].isLast = true;
          const index = _rows.indexOf(_rows.filter(item => item.child['REVIEW_CRITERIA_GRID_ELEMENT_ID'] === row['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'])[0]);
          _rows.splice(index + 1, 0, ...children);
        } else if (_rows.filter(child => child.child['REVIEW_CRITERIA_GRID_ELEMENT_ID'] === row['REVIEW_CRITERIA_GRID_ELEMENT_ID']).length === 0) {
          const _row: any = {};
          _row.value = '';
          _row.error = {};
          _row['REVIEW_CRITERIA_GRID_ELEMENT_TYPE'] = row['REVIEW_CRITERIA_GRID_ELEMENT_TYPE'];
          _row['REVIEW_CRITERIA_GRID_ELEMENT_METADATA_IS_ANONYMIZED'] = row['REVIEW_CRITERIA_GRID_ELEMENT_METADATA_IS_ANONYMIZED'] === "1";
          _row.child = cloneDeep(row);
          if (row.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION) {
            _row.child['REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION'] = {};
            this.translate.langs.forEach((lang) => {
              _row.child['REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION'][lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, row.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_DESCRIPTION, lang);
            });
          }
          _rows.push(_row);
        }
      });
      _rows = _rows.map((row) => {
        row.child.paddingLeft = (this.determineLevel(row, _rows, 0) * this.interLevelPaddingLeft).toString(10);
        _rows.filter(_row => _row.child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] === row.child['REVIEW_CRITERIA_GRID_ELEMENT_ID']).forEach((__row) => {
          if ((__row.child['REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_IS_MANDATORY'] === 'true' ||
            __row.child['REVIEW_CRITERIA_GRID_ELEMENT_LIST_IS_MANDATORY'] === 'true' ||
            __row.child['REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_IS_MANDATORY'] === 'true')) {
            row.child.REVIEW_CRITERIA_GRID_ELEMENT_LABEL_IS_MANDATORY = 'true';
          }
        });
        return row;
      });
      console.log(_rows);
      this._bphChange.roundInputTo = ((this.globalPrj.formData['REVIEW_GRID_DATA'].data[0]['ROUND_INPUT_TO'] || '').split('.')[1] || []).length;
      this._bphChange.roundDisplayTo = ((this.globalPrj.formData['REVIEW_GRID_DATA'].data[0]['ROUND_DISPLAY_TO'] || '').split('.')[1] || []).length;
      if (this.globalPrj.formData['REVIEW_CRITERIA_GRID_RATING_SCALE'] && this.globalPrj.formData['REVIEW_CRITERIA_GRID_RATING_SCALE'].data.length > 0) {
        this._bphChange.ratingScaleDetails = this.globalPrj.formData['REVIEW_CRITERIA_GRID_RATING_SCALE'].data.filter((rsd) => rsd['NAME']);
        this._bphChange.ratingScaleMaxScore = this.globalPrj.formData['REVIEW_CRITERIA_GRID_RATING_SCALE'].data[0]['RATING_SCALE_MAX_SCORE'];
        this._bphChange.ratingScaleMinScore = this.globalPrj.formData['REVIEW_CRITERIA_GRID_RATING_SCALE'].data[0]['RATING_SCALE_MIN_SCORE'];
      }
      if (this.globalPrj.formData['OTHER_REVIEWERS_SCORE'] && this.globalPrj.formData['OTHER_REVIEWERS_SCORE'].data.length > 0) {
        this.othersReviewersScores = this.globalPrj.formData['OTHER_REVIEWERS_SCORE'].data;
        this.othersReviewersMaxScore = 100 - this._bphChange.ratingScaleMaxScore;
        let avgScore = 0;
        this.othersReviewersScores.map(score => {
          avgScore += Number(score['GLOBAL_QUOTE']);
        });
        avgScore = Number(((avgScore / this.othersReviewersScores.length / 100) * this.othersReviewersMaxScore).toFixed(this._bphChange.roundDisplayTo || 2));
        this.othersReviewersAvgScore = avgScore;
      }
      this._bphChange.rows = _rows;
      console.log(_rows);
    }
  }


  determineLevel(row: any, allRows: any[], level: number): number {
    const filteredRows = allRows.filter(_row => _row.child['REVIEW_CRITERIA_GRID_ELEMENT_ID'] === row.child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID']);
    if (filteredRows.length === 0) {
      return level;
    } else {
      return this.determineLevel(filteredRows[0], allRows, level + 1);
    }
  }

  adjustOrderByWeight(rows: any[], parentGridElementId: string): any[] {
    let _rows = [];
    rows.filter(row => row.child && row.child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] === parentGridElementId).forEach(parent => {
      _rows.push(parent);
      rows
        .filter(row => row.child && (row.child['REVIEW_CRITERIA_GRID_ELEMENT_PARENT_ID'] === parent.child['REVIEW_CRITERIA_GRID_ELEMENT_ID']))
        .sort((a, b) => {
          if (Number(a['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT']) < Number((b['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT']))) {
            return -1;
          } else if (Number(a['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT']) > Number(b['REVIEW_CRITERIA_GRID_ELEMENT_WEIGHT'])) {
            return 1;
          }
          return 0;
        }).forEach(child => {
        _rows.push(child);
        _rows = _rows.concat(this.adjustOrderByWeight(rows, child.child['REVIEW_CRITERIA_GRID_ELEMENT_ID']));
        });
    });
    return _rows;
  }


  retrieveLovs() {
    this.lovDataService.getAllTermsWithSequence(this.lovsToBeRetrieved.filter(lovId => !this.globalConf.lovs[Number(lovId)]).map(lovId => Number(lovId)))
      .subscribe((response: any) => {
        if (response.status) {
          response.data.forEach((lov) => {
            this.globalConf.lovs[lov.taxonomyId] = {
              'name': lov.taxonomyId.toString(),
              'list': lov.terms.map((lovItem) => {
                const dummyVar = cloneDeep(lovItem.value);
                lovItem.value = cloneDeep(lovItem.id);
                lovItem.label = dummyVar;
                return lovItem;
              })
            };
          });
        } else {
          this.eCaseSnackBarService.show('failure', response.errorMessage);
        }
      });
  }

  retrieveOriginalData() {
    this.originalDataArray.forEach((row) => {
      this._bphChange.rows.filter(_row => _row.child['REVIEW_CRITERIA_GRID_ELEMENT_ID'] === row.child['REVIEW_CRITERIA_GRID_ELEMENT_ID'])[0].child.value = row.child.value;
    });
  }

  getNumericTextLength(l): number {
    try {
      return l.toString().replace('\.', '').length;
    } catch (e) {
      return 0;
    }
  }

  getTextLength(l): number {
    try {
      return l.length;
    } catch (e) {
      return 0;
    }
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  computeScore(child): number {
    return 0;
  }

  getWidthFromMaxlength(child, maxlength) {
    child.maxlength = maxlength;
    if (child.maxlength && !(this.platform.ANDROID || this.platform.IOS)) {
      if (child.maxlength <= 10) {
        return '15%';
      } else if (child.maxlength > 10 && child.maxlength <= 50) {
        return '50%';
      } else if (child.maxlength > 50) {
        return '100%';
      }
    } else {
      return '100%';
    }
  }

  toNumber(value) {
    return Number(value);
  }

  change(nv, c, s) {
    s.value = {};
    s.value.value = nv;
    if (nv === '') {
      s.value.label = {};
      return;
    }
    try {
      this.globalConf.lovs[Number(s['REVIEW_CRITERIA_GRID_ELEMENT_LIST_LOV_ID'])].list.forEach((e) => {
        if (e.value === nv) {
          s.value.label = e.label;
          s.value.source = e.source;
          s.value.source_id = e.source_id;
          s.value.data = e.data;
          s.value.is_other = e.is_other;
          if (c.subList) {
            ((this.globalPrj[c.subList.split('.')[0]])[c.subList.split('.')[1]]).listName = e.subList ? e.subList : 'empty_list';
            ((this.globalPrj[c.subList.split('.')[0]])[c.subList.split('.')[1]]).value = {};
            ((this.globalPrj[c.subList.split('.')[0]])[c.subList.split('.')[1]]).value.value = '';
            ((this.globalPrj[c.subList.split('.')[0]])[c.subList.split('.')[1]]).value.label = {};
            const data: any = {};
            data.subListComponent = this.confChange.subList;
            data.newListName = e.subList ? e.subList : 'empty_list';
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
    this.updateFormField(nv, s);
  }

  checkMaxComment($event, child) {
    let isValid = true;
    switch (child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH_UNIT) {
      case "C":
        isValid = this.getTextLength($event.target.value) <= child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH;
        break;
      default:
        isValid = this.getWordCount($event.target.value) <= child.REVIEW_CRITERIA_GRID_ELEMENT_COMMENT_MAX_LENGTH;
        break;
    }
    if (isValid) {
      child.error[this.selectedLanguage] = false;
      this.updateFormField($event, child);
    } else {
      child.error[this.selectedLanguage] = "ecase.formbuilder.review.criteria.grid.textLengthError";
    }
  }

  checkMaxQuotation($event, child) {
    if(this.confChange.decimalSeparator && this.confChange.decimalSeparator !=='') {
      const regex = new RegExp('^[+-]?([0-9]+\\' + this.confChange.decimalSeparator +'?[0-9]*|\\.[0-9]+)$');
      if(!regex.test($event.target.value)) {
        $event.target.value = $event.target.value.slice(0, -1);
      }
    }

    $event = this.changeValueEvent($event, child);
    const isValid = Number($event.target.value) <= Number(child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE);
    if (isValid) {
      child.error[this.selectedLanguage] = false;
      child.value = $event.target.value;
      this.updateFormField($event, child);
    } else {
      child.error[this.selectedLanguage] = "ecase.formbuilder.review.criteria.grid.quoteScoreError";
    }
  }

  changeValueEvent($event, child) {
    let roundInputTo = child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_ROUND_INPUT_TO || this.globalPrj.formData['REVIEW_GRID_DATA'].data[0]['ROUND_INPUT_TO'] || '';
    const separator = this.confChange.decimalSeparator && this.confChange.decimalSeparator !== '' ?
      this.confChange.decimalSeparator :
      roundInputTo.includes('.') ? '.' : roundInputTo.includes(',') ? ',' : '';
    if (separator !== '') {
      roundInputTo = child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE.length + 1 + ((roundInputTo.split(separator)[1] || []).length)
    } else {
      roundInputTo = child.REVIEW_CRITERIA_GRID_ELEMENT_QUOTE_MAX_SCORE.length;
    }
    if ($event.target.value && $event.target.value < (this._bphChange.ratingScaleMinScore || 0)) {
      $event.target.value = this._bphChange.ratingScaleMinScore;
    }
    if ($event.target.value.includes(separator)) {
      $event.target.value = $event.target.value.slice(0, roundInputTo);
    }
    return $event;
  }

  updateFormField(event, block) {
    this.pendingChangesGuard.isPristine = false;
    if (this.confChange.enableOutputEvent) {
      const obj = {
        event: event,
        block: block,
      };
      this.emitOutputEvent.emit(obj);
    }
  }

  checkRequired(child, isRequired) {
    if (isRequired && !child.value) {
      child.error[this.selectedLanguage] = "ecase.formbuilder.review.criteria.grid.requiredField";
    }
  }

  computeRating(minScore, maxScore, idx) {
    if (!this._bphChange.computedScore) {
      return false;
    } else if (minScore !== maxScore) {
      return this._bphChange.computedScore >= minScore && this._bphChange.computedScore <= maxScore;
    } else {
      return (idx === 0 && this._bphChange.computedScore >= maxScore) || this._bphChange.computedScore >= minScore && this._bphChange.computedScore < this._bphChange.ratingScaleDetails[idx-1]['MAX_SCORE'];
    }
  }

}
