import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  eCaseAnimations,
  ECaseDataTable,
  ECaseDateFormatterPipe,
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService, ECaseSplashScreenService, ECaseUtils,
  LoginAuthenticationService,
  LovDataService,
  ParametersService,
  ProjectService
} from 'synto-common';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import cloneDeep from "lodash/cloneDeep";
import {of} from "rxjs/internal/observable/of";
import {MatDialog} from "@angular/material/dialog";
import {AddNewDocumentDialogComponent} from "./add-new-document-dialog/add-new-document-dialog";
import {combineLatest} from "rxjs/internal/observable/combineLatest";

/**
 * Created by sahilb@evision.ca on 2020-10-14.
 */
@Component({
  selector: 'app-document-listing',
  templateUrl: './document-listing.component.html',
  styleUrls: ['./document-listing.component.scss'],
  animations: eCaseAnimations
})
export class DocumentListingComponent extends ECaseDataTable implements OnInit, OnDestroy {

  @Input() isPreviewBeforeDownload;
  @Input() columnsToBeHidden;
  @Input() defaultSortedColumn;
  @Input() defaultSortOrder;
  @Input() accessLocation;
  @Input() downloadAllArchiveName;
  @Input() isDisableInitialColumnSorting: boolean;
  @Input() excludeLiveFromArchive: boolean;
  @Input() showAddNewDocumentButton: boolean;
  @Input() allowMultipleFileUpload: boolean;
  @Input() documentTypesForNewDocument: any[];
  @Input() externalDataForNewDocument: any;
  /*
  * context is an JSON object which will contains 3 keys like
  * {
  * "id": 12345,
  * "type": caseApplicationId,
  * }
  * */
  @Input() context;
  @Output() refreshDataEvent = new EventEmitter<any>();
  displayedColumns = ['resetFilter', 'cpmFormTypeId', 'fileName', 'documentCategoryLabel', 'documentTypeLabel', 'size',
    'teamMemberRoleLabel', 'creationDate', 'lastUpdatedDate', 'createdBy'];
  filterColumns = ['header-row-zeroth-group', 'header-row-first-group', 'header-row-two-group', 'header-row-third-group',
    'header-row-fourth-group', 'header-row-fifth-group', 'header-row-sixth-group', 'header-row-seventh-group',
    'header-row-eighth-group', 'header-row-ninth-group'];
  filterObject = {
    'fileName': '',
    'creationDate': '',
    'lastUpdatedDate': '',
    'createdBy': '',
    'size': ''
  };
  filterObjectForSelectFields = {
    'documentTypeId': '',
    'teamMemberRoleId': '',
    'cpmFormTypeId': '',
    'documentCategoryId': ''
  };
  sortActive = 'fileName';
  sortDirection = 'asc';
  window;
  lang;
  roles = [];
  documentTypes = [];
  formTypes = [];
  documentCategories = [];
  counter = 0;

  constructor(translate: TranslateService, parameterService: ParametersService, private eCaseSnackBarService: ECaseSnackBarService,
              public loginAuthenticationService: LoginAuthenticationService, private eCaseHttpService: EcaseHttpService,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe, private eCaseDateFormatter: ECaseDateFormatterPipe,
              private lovDataService: LovDataService, public dialog: MatDialog, private projectService: ProjectService,
              private eCaseSplashScreenService: ECaseSplashScreenService) {
    super(translate, parameterService);
    this.serviceArray = this.dataSourceArray;
  }

  private _dataSourceArray: any[];

  get dataSourceArray(): any[] {
    return this._dataSourceArray;
  }

  @Input() set dataSourceArray(value: any) {
    value = value ? value : [];
    let inputValue = cloneDeep(value);
    if (value && !Array.isArray(value)) {
      this.isDisableInitialColumnSorting = inputValue.isDisableInitialColumnSorting;
      inputValue = inputValue.dataSourceArray;
    }
    inputValue = this.reviseDatasource(inputValue);
    this._dataSourceArray = inputValue;
    this.originalArray = this._dataSourceArray;
    this.serviceArray = this._dataSourceArray;
    this.dataSource = new MatTableDataSource(this._dataSourceArray);
    if (!this.isDisableInitialColumnSorting) {
      this.sortColumn({active: this.sortActive, direction: this.sortDirection});
    } else {
      if (this.counter !== 0) {
        this.sortColumn({active: this.sortActive, direction: this.sortDirection});
      } else {
        this.counter = this.counter + 1;
      }
    }
  }

  @Input() addNewDocumentFunction = (eCaseHttpService: EcaseHttpService, formData: FormData): Observable<any> => {
    return of({
      'status': false,
      'errorMessage': 'Not implemented'
    });
  };

  reviseDatasource(value: any[]): any {
    value = value.map((item) => {
      // Format dates and sizes
      item.formattedCreationDate = item.creationDate ? item.creationDate : '';
      item.formattedLastUpdatedDate = item.lastUpdatedDate ? item.lastUpdatedDate : '';
      item.formattedSize = item.size ? this.eCaseNumberFormatterPipe.fileSizeTransform(item.size) : '';
      // Create pseudo-column documentCategoryId + fileName
      item.priorityFileName = (
        (item.documentCategoryId || 0) === 1
          ? (
            (item.documentTypeId || 0) === 7705
              ? '001-'
              : '002-'
          )
          : '009-'
      ) + item.fileName;
      // Create if missing
      item.cpmFormTypeLabel = item.cpmFormTypeLabel ? item.cpmFormTypeLabel : [];
      return item;
    });
    return value;
  }

  sortColumn(event: any, childPath?: string): void {
    this.sortActive = event.active;
    this.sortDirection = event.direction;
    this.dataSource.data = ECaseUtils.sortColumn(event, this.dataSource.data, this.translate, childPath);
    this.renewDataSource(this.dataSource.data);
  }

  renewDataSource(newDataArray): void {
    this.dataSource = new MatTableDataSource(newDataArray);
    this.sortingDataAccessorWithIgnoreCase();
    this.dataSource.paginator = this.paginator.toArray()[0];
  }

  ngOnInit(): void {
    this.updateVisibleColumns();
    if (!this.isPreviewBeforeDownload) {
      this.isPreviewBeforeDownload = false;
    }
    if (this.defaultSortedColumn) {
      this.sortActive = this.defaultSortedColumn;
    }
    if (this.defaultSortOrder) {
      this.sortDirection = this.defaultSortOrder;
    }
    this.lang = this.translate.getDefaultLang();
    this.window = window;
    this.lovDataService.getAllTermsWithSequence([34, 12, 33, 35, 66]).subscribe((response: any) => {
      const doc35 = (response.data.filter(item => item.taxonomyId === 35))[0].terms;
      const doc66 = (response.data.filter(item => item.taxonomyId === 66))[0].terms;
      this.documentTypes = doc35.concat(doc66);
      this.documentTypes = this.lovDataService.sortTermListAlphabetically(this.documentTypes, this.lang);
      this.formTypes = (response.data.filter(item => item.taxonomyId === 33))[0].terms;
      this.documentCategories = (response.data.filter(item => item.taxonomyId === 34))[0].terms;
      this.roles = (response.data.filter(item => item.taxonomyId === 12))[0].terms;
      this.sortAllTerms(this.formTypes);
      this.sortAllTerms(this.documentCategories);
      this.sortAllTerms(this.roles);
      this.sortAllTerms(this.documentTypes);


      // this.formTypes = ECaseUtils.sortLovAlphabeticallyWithoutSortingKey(response.data.filter(item => item.taxonomyId === 33)[0].terms, this.translate);
      // this.documentCategories = ECaseUtils.sortLovAlphabeticallyWithoutSortingKey(response.data.filter(item => item.taxonomyId === 34)[0].terms, this.translate);
      // this.roles = ECaseUtils.sortLovAlphabeticallyWithoutSortingKey(response.data.filter(item => item.taxonomyId === 12)[0].terms, this.translate);
      // this.documentTypes = ECaseUtils.sortLovAlphabeticallyWithoutSortingKey(response.data.filter(item => item.taxonomyId === 35)[0].terms, this.translate);
      if (!this._dataSourceArray) {
        this._dataSourceArray = [];
      }
      this.dataSource = new MatTableDataSource(this.dataSourceArray);
      if (!this.columnsToBeHidden) {
        this.columnsToBeHidden = [];
      }

      this.originalArray = this._dataSourceArray;
      this.serviceArray = this._dataSourceArray;
      this.dataSource = new MatTableDataSource(this._dataSourceArray);
      if (!this.isDisableInitialColumnSorting) {
        this.sortColumn({active: this.sortActive, direction: this.sortDirection});
      }
      setTimeout(() => {
        this.filterByExistent(this.originalArray);
      }, 1500);
      //  this.setDataSourceAttributes();

    });
  }

  updateVisibleColumns(): void {
    this.columnsToBeHidden.forEach((columnName) => {
      const indexToBeDeleted = this.displayedColumns.indexOf(columnName);
      if (indexToBeDeleted !== -1) {
        this.displayedColumns.splice(indexToBeDeleted, 1);
        this.filterColumns.splice(indexToBeDeleted, 1);
        delete this.filterObject[columnName];
        delete this.filterObjectForSelectFields[columnName];
      }
    });
    if (this.displayedColumns.includes('creationDate')) {
      this.displayedColumns[this.displayedColumns.indexOf('creationDate')] = 'formattedCreationDate';
      delete this.filterObject.creationDate;
      this.filterObject['formattedCreationDate'] = '';
    }
    if (this.displayedColumns.includes('lastUpdatedDate')) {
      this.displayedColumns[this.displayedColumns.indexOf('lastUpdatedDate')] = 'formattedLastUpdatedDate';
      delete this.filterObject.lastUpdatedDate;
      this.filterObject['formattedLastUpdatedDate'] = '';
    }
    if (this.displayedColumns.includes('size')) {
      this.displayedColumns[this.displayedColumns.indexOf('size')] = 'formattedSize';
      delete this.filterObject.size;
      this.filterObject['formattedSize'] = '';
    }
  }

  sortAllTerms(array): void {
    array = array.sort((term: any, _term: any) => {
      if (term.sortingKey === _term.sortingKey) {
        return this.sortTerms(term, _term);
      } else {
        return (term.sortingKey < _term.sortingKey ? -1 : 1);
      }
    });
  }

  sortTerms(firstTerm, secondTerm): any {
    return (firstTerm.value[this.lang]).localeCompare(secondTerm.value[this.lang]);
  }

  downloadAll(): void {
    let request: Observable<any>;
    let url;
    let httpRequestType = '';
    this.eCaseSplashScreenService.show();
    const isLive = this.originalArray.some(e => e.edmRepositoryId === -1 || e.edmRepositoryId === -4) && !this.excludeLiveFromArchive;
    if (isLive) {
      const fileNames = [];
      combineLatest(this.originalArray.map(a => {

          let fileName = a.fileName;
          if (a.edmRepositoryId === -4 && a.fileName.includes('ecase.')) {
            fileName = a.cpmFormTypeLabel && a.cpmFormTypeLabel[this.lang] ? a.cpmFormTypeLabel[this.lang] : '';
            fileName += ECaseUtils.getTranslatedValueFromKey(this.translate, a.fileName) + '.pdf';
            fileName = fileName.replace(/\s/g, '_');
          }
          fileNames.push(fileName);
          const payLoad = {
            'accessLocation': this.accessLocation
          };
          if (a.edmRepositoryId === -1 && a.applicationId && a.teamMemberId && !a.isAwf) {
            url = '/api/generatePdfForTeamMemberApplication/' + a.applicationId + '/' + a.teamMemberId;
            httpRequestType = 'GET';
          } else if (a.edmRepositoryId === -1 && a.applicationId && a.teamMemberId && a.isAwf) {
            url = '/api/generatePdfForApplication/' + a.applicationId + '?isAwf=1';
            httpRequestType = 'GET';
          } else if (a.edmRepositoryId === -4 && a.applicationId) {
            url = '/api/generateArchivedPdfForTeamMemberApplication';
            httpRequestType = 'POST';
            payLoad['applicationId'] = Number(a.applicationId);
            payLoad['fileName'] = fileName;
            payLoad['teamMemberId'] = a.teamMemberId;
          } else {
            url = '/api/viewDocument/' + a.edmRepositoryId;
            httpRequestType = 'POST';
          }

          switch (httpRequestType) {
            case 'GET' : {
              request = this.eCaseHttpService.get(this.loginAuthenticationService.baseUrl + url, {
                observe: 'response',
                responseType: 'blob'
              });
              break;
            }
            case 'POST' : {
              request = this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + url, payLoad, {
                observe: 'response',
                responseType: 'blob'
              });
              break;
            }
          }
          return request;
        })
      ).subscribe({
        next: (responses: Array<HttpResponse<Blob>>) => {
          responses.forEach((response, index) => {
            ECaseUtils.generateHyperLinkForDownload(response, fileNames[index]);
          });
        },
        error: (error) => {
          this.eCaseSnackBarService.show('failure', error.errorMessage);
        },
        complete: () => {
          this.eCaseSplashScreenService.hide();
        }
      });
    } else {
      url = '/api/downloadAllDocuments';
      const docsAllowed = this.originalArray.map((doc) => doc.edmRepositoryId);
      ECaseUtils.setKeyValueInTheSession(this.eCaseHttpService, 'documentsAllowedToDownload', docsAllowed, false).subscribe((res: any) => {
        request = this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + url, {
          'accessLocation': this.accessLocation,
          'context': this.context
        }, {
          observe: 'response',
          responseType: 'blob'
        });
        request.subscribe({
          next: (response: HttpResponse<Blob>) => {
            ECaseUtils.generateHyperLinkForDownload(response, this.downloadAllArchiveName);
          },
          error: (error) => {
            this.eCaseSnackBarService.show('failure', error.errorMessage);
          },
          complete: () => {
            this.eCaseSplashScreenService.hide();
          }
        });
      });
    }
  }

  addNewDocument(): void {
    if (this.showAddNewDocumentButton) {
      const dialogRef = this.dialog.open(AddNewDocumentDialogComponent, {
        width: '700px',
      });
      dialogRef.componentInstance.allowMultipleFileUpload = this.allowMultipleFileUpload;
      dialogRef.componentInstance.documentTypes = this.documentTypesForNewDocument;
      dialogRef.componentInstance.externalData = this.externalDataForNewDocument;
      dialogRef.componentInstance.addNewDocumentFunction = this.addNewDocumentFunction
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.refreshDataEvent.emit(true);
        }
      });
    } else {
      this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.add.new.document.function.not.implemented'));
    }
  }

  preview(row): void {
    if (row.edmRepositoryId === -1) {
      this.window.open(this.loginAuthenticationService.baseUrl + '/api/generatePdfApplicationAdmin/' +
        row.applicationId + '/' + row.teamMemberId + '/' + row.formTypeId);
    } else if (row.edmRepositoryId === -2) {
      this.window.open(this.loginAuthenticationService.baseUrl + '/api/generatePdfApplicationAdminRevision/' +
        row.applicationId + '/' + row.teamMemberId + '/' + row.formTypeId);
    } else if (row.edmRepositoryId === -3) {
      this.window.open(this.loginAuthenticationService.baseUrl + '/api/generatePdfApplicationAdminReview/' +
        row.applicationId + '/' + row.teamMemberId + '/' + row.formTypeId);
    } else if (row.edmRepositoryId === -4 && row.applicationId && row.fileName) {
      const payLoad = {
        'accessLocation': this.accessLocation
      };
      payLoad['applicationId'] = Number(row.applicationId);
      payLoad['fileName'] = row.fileName;
      payLoad['teamMemberId'] = row.teamMemberId;
      this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + '/api/generateArchivedPdfForTeamMemberApplication', payLoad, {
        observe: 'response',
        responseType: 'blob'
      }).subscribe({
        next: (response: HttpResponse<Blob>) => {
          let fileName = row.fileName;
          if (typeof row !== 'string' && (row.edmRepositoryId === -4 && row.applicationId && row.fileName)) {
            fileName = row.cpmFormTypeLabel[this.lang] + ' ' + ECaseUtils.getTranslatedValueFromKey(this.translate, row.fileName, this.lang) + '.pdf';
          }
          ECaseUtils.generateHyperLinkForDownload(response, fileName);
        },
        error: (error) => {
          this.eCaseSnackBarService.show('failure', error.errorMessage);
        }
      });
    } else {
      ECaseUtils.setKeyValueInTheSession(this.eCaseHttpService, 'documentId', row.edmRepositoryId, false).subscribe((res: any) => {
        if (res.status) {
          this.window.open(this.loginAuthenticationService.baseUrl + '/api/reviewer/viewAttachedRepositoryDocumentSecured');
        }
      });
    }
  }

  download(row): void {
    const documentsAllowedToDownload = [];
    documentsAllowedToDownload.push(row.edmRepositoryId)
    ECaseUtils.setKeyValueInTheSession(this.eCaseHttpService, 'documentsAllowedToDownload', documentsAllowedToDownload, false).subscribe((res: any) => {

      let url;
      let httpRequestType = '';
      const payLoad = {
        'accessLocation': this.accessLocation
      };
      if (typeof row === 'string') {
        url = row;
        httpRequestType = 'POST';
      } else {
        if (row.edmRepositoryId === -1 && row.applicationId && row.teamMemberId && !row.isAwf) {
          url = '/api/generatePdfForTeamMemberApplication/' + row.applicationId + '/' + row.teamMemberId;
          httpRequestType = 'GET';
        } else if (row.edmRepositoryId === -1 && row.applicationId && row.teamMemberId && row.isAwf) {
          url = '/api/generatePdfForApplication/' + row.applicationId + '?isAwf=1';
          httpRequestType = 'GET';
        } else if (row.edmRepositoryId === -4 && row.applicationId && row.fileName) {
          url = '/api/generateArchivedPdfForTeamMemberApplication';
          httpRequestType = 'POST';
          payLoad['applicationId'] = Number(row.applicationId);
          payLoad['fileName'] = row.fileName;
          payLoad['teamMemberId'] = row.teamMemberId;
        } else {
          url = '/api/viewDocument/' + row.edmRepositoryId;
          httpRequestType = 'POST';
        }
      }
      let request: Observable<any>;
      switch (httpRequestType) {
        case 'GET' : {
          request = this.eCaseHttpService.get(this.loginAuthenticationService.baseUrl + url, {
            observe: 'response',
            responseType: 'blob'
          });
          break;
        }
        case 'POST' : {
          request = this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + url, payLoad, {
            observe: 'response',
            responseType: 'blob'
          });
          break;
        }
      }
      request.subscribe({
        next: (response: HttpResponse<Blob>) => {
          let fileName = row.fileName;
          if (typeof row !== 'string' && (row.edmRepositoryId === -4 && row.applicationId && row.fileName)) {
            fileName = row.cpmFormTypeLabel[this.lang] + ' ' + ECaseUtils.getTranslatedValueFromKey(this.translate, row.fileName, this.lang) + '.pdf';
          }
          ECaseUtils.generateHyperLinkForDownload(response, fileName);
        },
        error: (error) => {
          this.eCaseSnackBarService.show('failure', error.errorMessage);
        }
      });
    });
  }

  ngOnDestroy(): void {
    ECaseUtils.removeKeyListFromSession(this.eCaseHttpService, ['documentsAllowedToDownload']).subscribe(() => {

    });
  }

  filterByExistent(tableList): void {
    const cpmFormTypeIdList = [];
    const documentTypeIdList = [];
    const documentCategoryIdList = [];
    const teamMemberRoleIdList = [];
    tableList.forEach((it) => {
      cpmFormTypeIdList.push(it.cpmFormTypeId);
      documentTypeIdList.push(it.documentTypeId);
      documentCategoryIdList.push(it.documentCategoryId);
      teamMemberRoleIdList.push(it.teamMemberRoleId);
    });

    this.documentTypes = this.documentTypes.filter(it => documentTypeIdList.includes(it.id));
    this.documentCategories = this.documentCategories.filter(it => documentCategoryIdList.includes(it.id));
    this.roles = this.roles.filter(it => teamMemberRoleIdList.includes(it.id));
    this.formTypes = this.formTypes.filter(it => cpmFormTypeIdList.includes(it.id));
  }
}
